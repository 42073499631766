import { Component, OnInit, Injector } from '@angular/core';
import { BaseComponent } from 'src/app/common/commonComponent';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styles: []
})
export class DetailsComponent extends BaseComponent implements OnInit {
  public submitted: any;
  public detail: any = {};
  public data;
  public user: any;
  public url;
  constructor(inj: Injector, public BsModalRef: BsModalRef) { super(inj) }

  ngOnInit() {
    this.detail.status = 'Yes';
    if (this.BsModalRef) {
      this.BsModalRef.hide();
    }

  }
  onSubmit() {
    this.submitted = true;
    {
      if (this.detail.status == 'Yes' || this.detail.status == 'No') {
        this.submitted = false;
        this.BsModalRef.hide();
        let data: any = {};

        // "emailId": "pallari22@grr.la",
        // "authCentre": "5e2962eae468c87855d5a2eb",
        // "examId" : "5f34cf0453fa254e89b6058d",
        // "qualification" : "5e2eb951f9b4a2e2dee3439c"
        data.authCentre = this.data._id;
        data.qualificationScheduleDate = this.user.qualificationScheduleDate;
        data.examId = this.user.examId;
        data.status = this.detail.status;
        this.setToken('offerPageData', JSON.stringify(data))
        // this.router.navigate(['public/landing-page'], {
        //   queryParams: {
        //     firstName: this.router.parseUrl(this.router.url).queryParams['firstName'],
        //     lastName: this.router.parseUrl(this.router.url).queryParams['lastName'],
        //     email: this.router.parseUrl(this.router.url).queryParams['email']
        //     , mobile: this.router.parseUrl(this.router.url).queryParams['mobile'],
        //     qualificationId: this.router.parseUrl(this.router.url).queryParams['qualificationId'],
        //     city: this.router.parseUrl(this.router.url).queryParams['city'] ? this.toTitleCase(this.router.parseUrl(this.router.url).queryParams['city']) : 'Nellore',
        //   }
        // });
        this.router.navigate(['public/landing-page'], {
          queryParams: {
            firstName: this.router.parseUrl(this.router.url).queryParams['firstName'],
            lastName: this.router.parseUrl(this.router.url).queryParams['lastName'],
            email: this.router.parseUrl(this.router.url).queryParams['email']
            , mobile: this.router.parseUrl(this.router.url).queryParams['mobile'],
            qualificationId: this.user.qualification,
            city: this.user.city
          }
        });

        // this.commonService.callApi('/students/updateRegisteredStudentDetail', data, 'post', true, false).then((success) => {
        //   if (success.status === 1) {
        //     this.popToast('success', success.message);
        //     this.router.navigate(['public/landing-page'], {
        //       queryParams: {
        //         firstName: this.router.parseUrl(this.router.url).queryParams['firstName'], lastName: this.router.parseUrl(this.router.url).queryParams['lastName'], emailId: this.router.parseUrl(this.router.url).queryParams['email']
        //         , mobile: this.router.parseUrl(this.router.url).queryParams['mobile'],
        //         qualificationId: this.router.parseUrl(this.router.url).queryParams['qualificationId'], city: this.router.parseUrl(this.router.url).queryParams['city'] ? this.toTitleCase(this.router.parseUrl(this.router.url).queryParams['city']) : 'Nellore',
        //       }
        //     });
        //     // this.setToken('accessToken', success.access_token);
        //     // this.setToken('photo', success.data.photo);
        //     // if (success.data.role === 'Student/Parents') {
        //     //   if (!success.data.centreRole) {
        //     //     success.data.centreRole = 'Authorised_Centre';
        //     //   }
        //     // }
        //     // else {
        //     //   success.data.isProfileUpdated = true;
        //     // }
        //     // this.setToken('data', JSON.stringify(success.data));
        //     // this.router.navigate(['student-parent']);
        //     // this.router.navigate([this.URLConstants.BOOKEXAM, success._id, success.authCentre, success.authCentre]);
        //     // success.data[0].role === 'Student/Parents' ?
        //     //   this.router.navigate([this.URLConstants.COMMMON_DASHBOARD]) :
        //     //   (success.data[0].isProfileUpdated ? this.router.navigate([this.URLConstants.COMMMON_DASHBOARD]) :
        //     //     this.router.navigate([this.URLConstants.MY_PROFILE]));
        //   } else {
        //     this.popToast('error', success.message);
        //   }
        // });
      }
      else {
        this.BsModalRef.hide();
        this.router.navigate(['public/landing-page'], {
          queryParams: {
            firstName: this.router.parseUrl(this.router.url).queryParams['firstName'], lastName: this.router.parseUrl(this.router.url).queryParams['lastName'], emailId: this.router.parseUrl(this.router.url).queryParams['email']
            , mobile: this.router.parseUrl(this.router.url).queryParams['mobile'],
            qualificationId: this.router.parseUrl(this.router.url).queryParams['qualificationId'], city: this.router.parseUrl(this.router.url).queryParams['city'] ? this.toTitleCase(this.router.parseUrl(this.router.url).queryParams['city']) : 'Nellore',
          }
        });
        this.popToast('error', 'student not registered');
      }
    }
  }
  toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }
}
