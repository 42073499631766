import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'src/app/common/commonComponent';

@Component({
  selector: 'app-dynamic-filter',
  templateUrl: './dynamic-filter.component.html',
  styles: [],
})
export class DynamicFilterComponent extends BaseComponent implements OnInit {
  public filterlist: any[] = [];
  constructor(inj: Injector) { super(inj); }
  public statusFormData: any = {};
  public bindLabel: any;
  public bindValue: any;
  public selectedValue: any;
  @Input() fields: any;
  @Output() filterValue = new EventEmitter();
  @Input() type: any;
  public data: any;
  public filter: any[] = [];
  public ApiParameters: any = {};
  @Input() multiple: any;
  ngOnInit() {

  }
  submitStatusForm(statusForm, statusFormData) {

  }
  /*************************************************************
@purpose :To GetFilterList.
/*************************************************************/
  getFilterlistData(colName, event) {
    this.filterlist = [];
    if (this.type !== 'downloadCsv') {
      if (colName === 'Country1') {
      } else if (colName === 'Qualification' || colName === 'Mode' || colName === 'QUALIFICATION' || colName === 'Qualification' || colName === 'PreparationCentreList' || colName === 'City' || colName === 'Entity Type' || colName === 'Recipient Type') {
        this.data = {
          page: 1,
          pagesize: 25,
        };
        if (colName === 'status') {
          this.filterlist = ['Active', 'Inactive'];
        }
        else if (colName === 'Recipient Type') {
          this.filterlist = ['Leads', 'Registered Candidates', 'Candidates To Be Registered'];
        }
        else {
          this.data['searchInFilter'] = event.target.value;
          let apiUrl: any;
          if (this.type === 'campaignChart') {

            this.data.isAnalytic = true;
            this.data.role = this.loginData.role;
            this.data._id = this.loginData._id;
            (colName === 'Qualification' ? this.data.column = 'qualificationName' : (colName === 'PreparationCentreList' ? this.data.column = 'prepCentreName' : (colName === 'Entity Type' ? this.data.column = 'categoryType' : this.data.column = 'mode')));
            apiUrl = '/notification/getColumnValues';
          } else if (this.type === 'centresList') {
            apiUrl = '/students/bookExamGetColumnValues';
            (colName === 'City' ? this.data.column = 'qualificationCity' : this.data.column = 'qualificationName');
          } else if (this.type === 'bookedExamList') {
            this.data.isStudentBookedExam = true;
            (colName === 'City' ? this.data.column = 'city' : this.data.column = 'qualificationName');
            apiUrl = '/students/bookExamGetColumnValues';
          }
          if (this.data.column === 'qualification' || this.data.column === 'qualificationName' || colName === 'Qualiification') {
            // this.filter.forEach((data1) => {
            //   for (let key in data1) {
            //     if (key === 'Entity Type' && (this.data.column === 'qualification' || this.data.column === 'qualificationName')) {
            //       this.data['filter'] = [{ categoryType: data1[key] }];
            //       this.CallAPIgetFilterList(apiUrl, this.data);
            //     }
            //   }
            // });
            this.CallAPIgetFilterList(apiUrl, this.data);

          } else {
            if(this.data.filter){
              delete this.data.filter;
            }
            this.CallAPIgetFilterList(apiUrl, this.data);

          }
        }

      }
    }
    else {
      if (colName === 'Entity Type') {
        this.getCategories();
        this.loginData.category.forEach(x => {
          this.filterlist = [];
        })
      }
      else if (colName === 'Qualification Dates') {
        this.getQualifcationDates(this.data.qualification);
      }

    }

  }
  getCategories() {
    this.commonService.callApi('/admin/getCategory', '', 'post', false, false).then((success) => {
      if (success.status == 1) {
        this.filterlist = [];
        success.data.categoryData.forEach(element => {
          this.filterlist.push(element.categoryType);
        });;
      }
    });
  }

  getQualifcationDates(qualification) {
    let data: any = {};
    data.qualification = qualification;
    this.commonService.callApi(this.callAPIConstants.getExamDatesBasedOnQualification, data, 'post', false, false).then((success) => {
      if (success.status == 1) {
        this.filterlist = [];
        // success.data.categoryData.forEach(element => {
        //   this.filterlist.push(element.categoryType);
        // });;
      }
    });
  }
  /*************************************************************
@purpose :To GetFilterList Values.
/*************************************************************/
  CallAPIgetFilterList(method, data) {
    this.commonService.callApi(method, data, 'post', false, false).then((success) => {
      if (success.status === 1) {
        this.filterlist = success.data.listing;
      }
    });
  }
  /*************************************************************
@purpose :To SelectFilter.
/*************************************************************/
  selectFilter(fieldName, event) {
    if (fieldName === 'status') {
      if (event === 'Active') {
        this.modifyFilterArr(fieldName, [true]);
      } else if (event === 'Inactive') {
        this.modifyFilterArr(fieldName, [false]);
      } else {
        this.modifyFilterArr(fieldName, [true, false]);
      }
    } else {
      this.modifyFilterArr(fieldName, event);
    }
  }
  /*************************************************************
@purpose :To ModifyFilterArr.
/*************************************************************/
  modifyFilterArr(fieldName, event) {
    if ((fieldName === 'Entity Type') && !event.length) {
      this.fields.forEach((element1) => {
        if (element1.name === 'Qualification') {
          element1['value'] = [];
        }
      });
      this.filter.forEach((field) => {
        for (let key in field) {
          if (key === 'QUALIFICATION') {
            delete field[key];
          }
        }
      });
    }
    let index = this.filter.findIndex((o) => Object.keys(o).includes(fieldName));
    if (index > -1) {
      this.filter[index][fieldName] = event;
    } else {
      this.filter.push({ [fieldName]: event });
    }
    this.ApiParameters.filter = JSON.parse(JSON.stringify(this.filter));
    this.ApiParameters.filter.forEach((data1, index) => {
      for (let key in data1) {
        if (key === 'AUTHORISED CENTRE') {
          data1['authCentreData.centreName'] = [];
          data1['authCentreData.centreName'] = data1[key];
          delete data1[key];
        } else if (key === 'PREPARATION CENTRE') {
          data1['prepCentreData.centreName'] = [];
          data1['prepCentreData.centreName'] = data1[key];
          delete data1[key];
        } else if (key === 'Qualification') {
          data1['qualificationName'] = [];
          data1['qualificationName'] = data1[key];
          delete data1[key];
        } else if (key === 'City') {
          data1['qualificationCity'] = [];
          data1['qualificationCity'] = data1[key];
          delete data1[key];
        } else if (key === 'PreparationCentreList') {
          let data2 = [];
          data2 = [data1[key].prepCentreName];

          // data1[key].forEach((element) => {
          //   data2.push(element.prepCentreName);
          // });
          data1.prepCentreName = [];
          // tslint:disable-next-line: no-element-overwrite
          data1['prepCentreName'] = data2;
          delete data1[key];
          this.data.role = 'Preparation_Centre';
        } else if (key === 'Mode') {
          data1['mode'] = [];
          data1['mode'] = data1[key];
          delete data1[key];
        } else if (key === 'Entity Type') {
          data1['categoryType'] = [];
          data1['categoryType'] = data1[key];
          delete data1[key];
        }
        else if (key === 'Recipient Type') {
          data1['recipientType'] = [];
          data1['recipientType'] = data1[key];
          delete data1[key];
        }
      }
    });
    if (this.type === 'campaignChart') {
      this.filterValue.emit(this.ApiParameters.filter);
    }

  }

  /*************************************************************
@purpose : To ApplyFilter.
/*************************************************************/
  applyFilter() {
    this.filterValue.emit(this.ApiParameters.filter);
    this.broadcaster.broadcast('filterApplied', { filter: this.ApiParameters.filter });
  }
}
