import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// common
// import { SharedModule } from './reusable/shared/shared.module';

import { CanAuthActivate, CanLoginActivate, CanPrepAuthActivate, CanProfileActivate, CanStudentParentActivate } from './common/auth.gaurd';
import { EditStudentDetailsComponent } from './modals/edit-student-details/edit-student-details.component';
import { ViewNotificationComponent } from './modals/view-notification/view-notification.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { DashboardComponent } from './dashboard/dashboard.component';
// import { ContactAcComponent } from './contact-ac/contact-ac.component';
import { StudentCalendarComponent } from './student-calendar/student-calendar.component';
import { OfflinePaymentComponent } from './modals/offline-payment/offline-payment.component';
import { UploadPermissonComponent } from './modals/upload-permisson/upload-permisson.component';
import { DetailsComponent } from './modals/details/details.component';
import { OfferDetailsComponent } from './modals/offer-details/offer-details.component';
import { EditPaymentComponent } from './modals/edit-payment/edit-payment.component';
import { StudentCalendarPreviousComponent } from './student-calendar-previous/student-calendar-previous.component';
import { SendStudentNotificationsComponent } from './modals/send-student-notifications/send-student-notifications.component';
// import { ExamPriceFixingComponent } from './main/';

const routes: Routes = [
  {
    path: 'public',
    canActivate: [CanLoginActivate],
    loadChildren: './public/public.module#PublicModule',
  },
  {
    path: 'main',
    // canActivate: [CanPrepAuthActivate, CanAuthActivate ],
    loadChildren: './main/main.module#MainModule',
  },
  {
    path: 'student-parent',
    canActivate: [CanStudentParentActivate],
    loadChildren: './student-parent/student-parent.module#StudentParentModule',
  },
  {
    path: 'my-profile',
    canActivate: [CanProfileActivate],
    component: MyProfileComponent,
    pathMatch: 'full',
  },
  {
    path: '',
    redirectTo: 'public',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    pathMatch: 'full',
  },
  // {
  //   path: 'contactAc',
  //   component: ContactAcComponent,
  //   pathMatch: 'full',
  // },
  {
    path: 'studentCalendar',
    component: StudentCalendarComponent,
    pathMatch: 'full',
  },
  {
    path: 'studentCalendar2020',
    component: StudentCalendarPreviousComponent,
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'public',
    pathMatch: 'full',
  },
];

export const AppEntryComponents = [
  EditStudentDetailsComponent,
  ViewNotificationComponent,
  OfflinePaymentComponent,
  UploadPermissonComponent,
  DetailsComponent,
  OfferDetailsComponent,
  EditPaymentComponent,
  SendStudentNotificationsComponent
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule { }

export const AppRoutingComponents = [
  MyProfileComponent,
  DashboardComponent,
  // ContactAcComponent
];
