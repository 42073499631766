import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/common/commonComponent';
import { IBreadcrumbs } from 'src/app/common/interfaces';

@Component({
  selector: 'app-centres-list',
  templateUrl: './centres-list.component.html',
  styles: [],
})
export class CentresListComponent extends BaseComponent implements OnInit {
  public tableSetupData: any = {};
  public list: any;
  public fields = [{ name: 'City' }, { name: 'Qualification' }];
  public type: any;
  public paymentData: any = {};
  constructor(public inj: Injector) {
    super(inj);
  }

  ngOnInit() {
    // this.paymentData = JSON.parse(this.getToken('data'));
    this.setDTableInitialData();
    this.type = 'centresList';



  }
  /*************************************************************/

  /*************************************************************/
  // Datatable config settings
  /*************************************************************/

  // sort:Boolean ==> to enable sorting
  // isVisible:Boolean ==>  show static column
  // columnVisibility:Boolean ==> dynamic changing visibility of columns
  // filter ==> filter for that colName

  /*************************************************************/

  setDTableInitialData() {
    let tempData = [
      { type: 'text', colName: 'Centre ID', colFieldname: 'qualificationCentre', sort: true, isVisible: true, filter: false },
      { type: 'text', colName: 'Centre Name', colFieldname: 'qualificationCentreName', sort: true, isVisible: true, filter: false },
      { type: 'text', colName: 'Region', colFieldname: 'qualificationCity', sort: true, isVisible: true },
      { type: 'text', colName: 'Exam', colFieldname: 'qualificationName', sort: true, isVisible: true, filter: false },
      // tslint:disable-next-line: max-line-length
      { type: 'text', colName: 'Date', colFieldname: 'qualificationScheduleDate', sort: true, isVisible: true, Date: true },
      // { type: 'text', colName: 'Fees', colFieldname: 'qualificationAmount', sort: true, isVisible: true, INR: true },
      { type: 'action', colName: 'Action', colFieldname: '', isVisible: true },

    ];
    this.tableSetupData.cols = tempData;
    this.tableSetupData.type = 'centresList';
    this.tableSetupData.noText = 'No Booking Centres Available';
    // if (this.paymentData.paymentStatus == 'Confirmed') {

    //   this.tableSetupData.actions = [
    //     { id: 1, buttonTitle: 'View', class: 'fa fa-eye text-danger', type: 'button' },
    //   ];
    //   // this.tableSetupData.btn = 'Booked';
    // } else {

    // }
    this.tableSetupData.actions = [
      { id: 1, buttonTitle: 'Book Exam', class: 'fa fa-eye text-primary', type: 'button1' },
    ];
    this.tableSetupData.conditions = {
      search: false,
      showTableHeader: true,
      showTableFooter: true,
      showApplyStatus: false,
      showExport: false,
      showColumnHide: false,
      showFilter: false,
      showFilterExport: false,
      showSaveFilter: false
    };
  }
  filterValue(data) {
    this.tableSetupData.filter = data;
    // this.broadcaster.broadcast('')
  }
}
