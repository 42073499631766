import { Component, Injector, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../common/commonComponent';

@Component({
  selector: 'app-qualification',
  templateUrl: './qualification.component.html',
})

export class QualificationComponent extends BaseComponent implements OnInit {

  @Input() qualifications: any[] ;

  constructor(inj: Injector) { super(inj); }

  ngOnInit() {}

}
