import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../../common/commonComponent';
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-edit-student-details',
  templateUrl: './edit-student-details.component.html',
})
export class EditStudentDetailsComponent extends BaseComponent implements OnInit {
  userData: any = {};
  submitted: boolean = false;
  public data;

  constructor(inj: Injector) {
    super(inj);
  }

  ngOnInit() {

    this.userData = Object.assign({}, this.data);

  }
  /**********************************************************************
       @PURPOSE      :  For calling UpdateCsvUserDetails API
       @PARAMETERS   : {
           form: Form, It is to know Form valid or not
           user: Object, User data
       }
   /**********************************************************************/
  UpdateCsvUserDeetails(form, user) {
    this.submitted = true;
    if (form.valid) {
      let data = {
        _id: user._id,
        // candidateName: user.candidateName,
        // firstName: user.firstName,
        // lastName: user.lastName,
        grade: user.grade,
        mobile: user.mobile,
      };
      this.commonService.callApi(this.callAPIConstants.CsvUserUpdate, data, 'post', false, false).then((success) => {
        if (success.status === 1) {
          this.popToast('success', success.message);
          form.reset();
          this.BsModalRef.hide();
          this.broadcaster.broadcast('detailsUpdated', 'CsvUserlist');
        } else {
          this.popToast('error', success.message);
        }
      });
    }
  }
}
