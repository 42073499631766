import { AfterViewInit, Component, Injector, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import Responsive from 'datatables.net-responsive';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, of, Subject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { EditStudentDetailsComponent } from 'src/app/modals/edit-student-details/edit-student-details.component';
import { ViewNotificationComponent } from 'src/app/modals/view-notification/view-notification.component';
import { BaseComponent } from '../../common/commonComponent';
import { ITableSetupData } from './../../common/interfaces';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { OfflinePaymentComponent } from 'src/app/modals/offline-payment/offline-payment.component';
import { EditPaymentComponent } from 'src/app/modals/edit-payment/edit-payment.component';
import { SendStudentNotificationsComponent } from 'src/app/modals/send-student-notifications/send-student-notifications.component';

@Component({
  selector: 'app-responsive-data-table',
  templateUrl: './responsive-data-table.component.html',
})
export class ResponsiveDataTableComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input('tableSetupData') tableSetupData: any;
  dtTrigger: Subject<any> = new Subject();
  @ViewChild('customPagination') customPagination: any;

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};

  // serarch related declarations
  searchKey: any;
  MainSearchdataSource: any;
  setFocus: boolean = true;
  // serarch related declarations

  APIparameters: any = {};
  total: any;
  public type: any;
  public noText: any;
  public submitted = false;
  public selectedUserList: any[] = [];
  public columnVisibilityOptions = false;
  public filterColumnOption = false;
  public params: any;
  public conditions: any;
  public tempData: any = [];
  public tableConfig: any;
  filterAppliedBroadcaster: any;
  tableData: any = {
    cols: ['id', 'author', 'title'],
    data: [],
    rowSelected: 0,
    value: false,
    type: '',
  };
  bsModalRef: any;
  paramId: any;
  public fields: any[] = [];
  dayViewBroadcastrer: any;
  csvUserBroadcaster: any;
  modalRef: BsModalRef;
  approved: boolean;
  offlinePaymentBroadcaster: any;
  @Input() CSVLeadData;
  selectedFileList: any =[];
  constructor(inj: Injector) {
    super(inj);
    // search related
    this.MainSearchdataSource = Observable.create((observer: any) => {
      observer.next(this.searchKey);
    }).pipe(mergeMap((value: string) => {
      if (this.setFocus) {
        this.searchLisitng(value);
      }
      this.setFocus = true;
      return [];
    }));
    // search related

    this.activatedRoute.params.subscribe((params: any) => {
      if (params.id) {
        this.paramId = params.id;
        this.APIparameters = { page: 1, pagesize: 10, _id: this.paramId };
      } else {
        this.APIparameters = { page: 1, pagesize: 10 };
      }
    });
    /**************************************************************
    @Purpose :To Update the listing of Centres.
  //************************************************************** */
    this.filterAppliedBroadcaster = this.broadcaster.on<any>('filterApplied').subscribe((success) => {
      if (success.filter) {
        this.APIparameters.filter = success.filter;
        this.callApiToGetData();
      }
    });
    this.offlinePaymentBroadcaster = this.broadcaster.on<any>('offlinePayment').subscribe((success) => {
      if (success) {
        this.toggle = success;
        this.callApiToGetData();
      }
    });

  }

  updateCsvUserlistBroadcast() {
    this.csvUserBroadcaster = this.broadcaster.on<boolean>('detailsUpdated').subscribe((success) => {
      this.callApiToGetData();
    });
  }



  ngOnInit() {
    this.tableConfig = this.tableSetupData;
    this.type = this.tableConfig.type;
    this.params = this.tableConfig.params;
    this.conditions = this.tableConfig.conditions;
    if (this.type === 'CSVlist' || this.type === 'Communicationlist') {
      this.APIparameters = { page: 1, pagesize: 10, _id: this.loginData._id };
    } else if (this.type === 'CsvUserlist' || this.type === 'qualificationStudentList') {
      this.activatedRoute.queryParams.subscribe((params: any) => {
        if (params.id) {
          this.paramId = params.id;
          this.APIparameters = (this.type === 'CsvUserlist' ? { page: 1, pagesize: 10, _id: this.paramId } :
            { page: 1, pagesize: 10, qualificationId: this.paramId });
        }
      });
    } else if (this.type === 'Templatelist') {
      this.paramId = this.loginData._id;
      // isAdminData: false, createdBy: this.paramId 
      this.APIparameters = { page: 1, pagesize: 10, };
      this.APIparameters.recipientType=this.tableSetupData.params.statusParams;
      this.APIparameters.isAdminTemplate=this.tableSetupData.isAdminTemplate;
    } else if (this.type === 'Sendnotificationlist') {
      // this.paramId = this.loginData._id;
      // isAdminData: true, categoryType: this.loginData.categoryId
      this.APIparameters = { page: 1, pagesize: 10, };
      this.APIparameters.recipientType=this.tableSetupData.params.statusParams;
      if (this.loginData.role === 'Authorised_Centre') {
        // this.APIparameters.createdBy = this.paramId;
        this.APIparameters.filter = [{ approvalStatus: ['Approved'] }];
      }
      if (this.loginData.role === 'Preparation_Centre') {
        // this.APIparameters.createdBy = this.loginData.parentAuthorizationCentre;
        this.APIparameters.filter = [{ approvalStatus: ['Approved'] }];
      }
    } else if (this.type === 'studentJourney') {
      this.APIparameters = { _id: this.paramId };
    } else if (this.type === 'campaignAnalyticsList') {
      this.APIparameters = { isPagination: true, page: 1, pagesize: 10 };
    } else if (this.type === 'examList') {
      this.APIparameters = { page: 1, pagesize: 10, qualificationScheduleDate: this.tableConfig.date };
    } else if (this.type === 'bookedExamList') {
      this.APIparameters = { page: 1, pagesize: 10 };
    } else if (this.tableSetupData['filter'] && this.type === 'centresList') {
      this.APIparameters.filter = this.tableSetupData['filter'];
      this.callApiToGetData();

    }
    else if (this.type == 'Examprice') {
      this.APIparameters = { page: 1, pagesize: 10 };
    }
    if (this.type === 'CsvUserlist') { this.updateCsvUserlistBroadcast(); }
    if (this.type === 'walkinList') { this.updateCsvUserlistBroadcast(); }
  }

  ngAfterViewInit() {
    if (this.isBrowser()) {

      // options for datatable
      this.dtOptions = {
        destroy: true,
        retrieve: true,
        searching: false,
        sorting: false,
        ordering: false,
        autoWidth: true,
        paging: false,
        info: false,
        // responsive: {
        //   details: {
        //     renderer: Responsive.renderer.listHiddenNodes(),
        //   },
        // },
        responsive: true,
        columnDefs: [
          { responsivePriority: 1, targets: 0 },
          { responsivePriority: 2, targets: -1 },
          { responsivePriority: 3, targets: -2 },
        ],
        language: {
          emptyTable: this.tableConfig.noText ? this.tableConfig.noText : "No Data",
        },
      };
      this.callApiToGetData();
    }
  }

  OnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();

  }

  /*************************************************************/
  // Api for List
  /*************************************************************/
  public getMethodName: any;
  public searchCsvUser: any;
  public CSV: any;
  public EXCEL: any;
  public DeleteUser: any;
  public ChangeStatus: any;
  public getFilterlist: any;
  public colVisible: any;
  public saveFilter: any;
  public getFilter: any;
  public deleteFilter: any;

  callApiToGetData() {
    if (this.type) {
      if (this.type === 'CsvUserlist') {
        this.searchKey ?
          this.getMethodName = this.callAPIConstants.SearchCsvUser :
          this.getMethodName = this.callAPIConstants.CsvUserListing;
        this.getFilterlist = this.callAPIConstants.CsvUserFilterList;
      } else if (this.type === 'CSVlist') {
        this.getMethodName = this.callAPIConstants.CsvListing;
        this.getFilterlist = this.callAPIConstants.CsvFilterList;
      } else if (this.type === 'leadsList') {
        this.getMethodName = this.callAPIConstants.LeadsListing;
        this.getFilterlist = this.callAPIConstants.LeadsFilterList;
      } else if (this.type === 'LeadsUserlist') {
        this.searchKey ? this.getMethodName = '/csvleadfile/csvLeadUserSearch' : this.getMethodName = this.callAPIConstants.LeadsUsersListing;
        this.getFilterlist = this.callAPIConstants.LeadsFilterList;
        this.APIparameters._id = this.tableSetupData.paramId;
      }
      else if (this.type === 'pclist') {
        this.getMethodName = this.callAPIConstants.PcListing;
        this.getFilterlist = this.callAPIConstants.PcFilterList;
      } else if (this.type === 'Communicationlist') {
        this.getMethodName = this.callAPIConstants.CommunicationListing;
        this.getFilterlist = this.callAPIConstants.CommunicationFilterList;
      } else if (this.type === 'Templatelist') {
        this.getMethodName = this.callAPIConstants.TemplateListing;
        this.getFilterlist = this.callAPIConstants.NotificationFilterList;
        this.DeleteUser='/notification/deleteNotificationTemplate'
      } else if (this.type === 'Sendnotificationlist') {
        this.getMethodName = this.callAPIConstants.SendNotificationTemplate;
        this.getFilterlist = this.callAPIConstants.NotificationFilterList;
      } else if (this.type === 'studentJourney') {
        this.getMethodName = this.callAPIConstants.StudentJourney;
      } else if (this.type === 'campaignAnalyticsList') {
        this.getMethodName = this.callAPIConstants.massEmailNotificationList;
        this.getFilterlist = this.callAPIConstants.NotificationFilterList;
      } else if (this.type === 'examList') {
        this.getMethodName = this.callAPIConstants.examListing;
      }
      else if (this.type == 'csvLeadsList' || this.type=='csvLeadsList1') {
        this.getMethodName = this.callAPIConstants.CSVLeadUsers;
        this.APIparameters.categoryType = this.CSVLeadData.categoryType;
        this.APIparameters.qualification = this.CSVLeadData.qualification;
      }
      else if (this.type == 'seletedLeadData') {
        let data = JSON.parse(this.getToken('data'));
        this.getMethodName = this.callAPIConstants.seletedLeadData;
        this.APIparameters = {};
        this.APIparameters.qualification = this.tableSetupData.id;
        this.APIparameters.role = data.role === 'Authorised_Centre' ? 'ac' : 'pc';

      }
      else if (this.type === 'Examprice') {
        this.getMethodName = this.callAPIConstants.QualificationListing;
        this.getFilterlist = this.callAPIConstants.QualificationgetColumnValues;
      } else if (this.type === 'walkinList') {
        this.getMethodName = this.callAPIConstants.WalkInListing;
        this.getFilterlist = this.callAPIConstants.WalkInFilterList;
        // Paid StudentListComponent.
        this.CSV = '/authcentre/paidRegisteredStudentCsvExport';
        this.EXCEL = '';
      } else if (this.type === 'qualificationStudentList') {
        if (this.tableSetupData.qualificationGrade) {
          this.APIparameters.qualificationGrade = this.tableSetupData.qualificationGrade;
        }
        this.tableSetupData.role === 'Authorised Centre' ? this.APIparameters.role = 'Authorised_Centre' : this.APIparameters.role = 'Preparation_centre';
        this.getMethodName = this.callAPIConstants.QualificationStudentList;
        this.getFilterlist = this.callAPIConstants.FilterForScheduledExamDetailById;
      } else if (this.type === 'candiadtesToBeRegsitered') {
        this.getMethodName = this.callAPIConstants.CandidatesToBeregisteredListing;
        this.getFilterlist = this.callAPIConstants.WalkInFilterList;
        // Candidates To Be Registered
        this.CSV = '/exam/enrolledStudentCsvExport';
        this.EXCEL = '';
      } else if (this.type === 'centresList') {
        this.getMethodName = this.callAPIConstants.BookingCentresListing;
        if (this.loginData.isLeadUser) {
          this.APIparameters.isLeadUser = true;
        }
      } else if (this.type === 'bookedExamList') {
        this.getMethodName = this.callAPIConstants.BookedExamListing;
      } else if (this.type === 'RegisteredCandidates') {
          this.getMethodName = this.callAPIConstants.ResgisteredCandiatesList;
        this.getFilterlist = this.callAPIConstants.RegisteredFilterList;
        // via csv StudentListComponent
        this.CSV = '/authcentre/csvStudentExport';
        this.EXCEL = '';
      }


      if (this.getMethodName) {
        this.commonService.callApi(this.getMethodName, this.APIparameters, 'post', false, false).then((success: any) => {
          if (success.status === 1) {
            if (this.type === 'RegisteredCandidates') {
              let object = [];
              if (success.data && success.data.listing) {
                // success.data.listing.forEach(obj => {

                //   if (obj.candidateData) {
                //     let student: any = {};
                //     student._id = obj._id;
                //     student.username = obj.candidateData.username;
                //     student.mobile = obj.candidateData.mobile;
                //     student.authCentreName = obj.candidateData.centreName;
                //     student.qualificationName = obj.candidateData.userQualficationName;
                //     student.emailId = obj.candidateData.emailId;
                //     student.userCategoryType = obj.candidateData.userCategoryType;
                //     student.centreRole = obj.candidateData.centreRole.replace('_', ' ');
                //     object.push(student);
                //   }
                // })
                this.tableData.data = success.data.listing;
                this.tableData.data.forEach(obj => {
                  obj.centreDataRole = obj.centreDataRole.replace('_', ' ');

                })
                this.tableData.data.city = '';
                this.tableData.data.map(ele => {
                  if (typeof (ele.centreCity) == 'object' && ele.centreCity.length) {
                    ele.centreCity.map((city, key) => {
                      if (key == 0) {
                        ele.city = city + ", "
                      }
                      else if (key + 1 == ele.centreCity.length) {
                        ele.city += city
                      }
                      else {
                        ele.city += city + ", "
                      }
                    })
                  }
                  else {
                    ele.city = ele.centreCity;
                  }
                })
              }
            }
            else if (this.type === 'qualificationStudentList') {
              let object = [];
              success.data.listing.forEach(obj => {

                if (obj.candidateData) {
                  let student: any = {};
                  // student._id = obj._id;
                  student.candidateFirstName = obj.candidateData.candidateFirstName;
                  student.candidateLastName = obj.candidateData.candidateLastName;
                  student.candidateContactNo = obj.candidateData.candidateContactNo;
                  student.candidateEmail = obj.candidateData.candidateEmail;
                  student.categoryType = obj.candidateData.categoryType;
                  student.candidateQualficationName = obj.candidateData.candidateQualficationName;
                  student.candidatePrepCentreId = obj.candidateData.candidatePrepCentreId;
                  student.candidatePrepCentreName = obj.candidateData.candidatePrepCentreName.replace('_', ' ');
                  object.push(student);
                }
              })
              this.tableData.data = object;
            }
            else if (this.type === 'centresList') {
              success.data.listing.forEach(obj => {
                // if (!obj.isBookExam) {
                this.tableData.data.push(obj);
                // }
              })
            }
            else {

              this.tableData.data = success.data.listing;
            }
            if (this.type === 'Communicationlist') {
              this.tableData.data.forEach((ele) => {
                if (ele['authCentreName']) {
                  ele.sentby = 'Authorised Centre';
                  ele.centreName = ele.authCentreName;
                } else if (ele['prepCentreName']) {
                  ele.sentby = 'Preparation Centre';
                  ele.centreName = ele.prepCentreName;
                }
              });
            }
            if (this.type === 'studentJourney') {
              this.tableData.data.forEach((element) => {
                element.qualificationName = element.qualification;
                element.year = element.lastStartYear + '-' + element.lastEndYear;
              });
            }
            if (this.type === 'Sendnotificationlist') {
              this.tableData.data.forEach((x: any) => {
                x.isSelected = false;
              });
            }
            if (this.type === 'CSVlist') {
              this.tableData.data.forEach((element) => {
                element.year = element.csvUploadedStartYear + '-' + element.csvUploadedEndYear;
                if (element.csvCentreRole === 'Authorised_Centre') {
                  element.csvCentreRole = 'Authorised Centre';
                } else {
                  element.csvCentreRole = 'Preparation Centre';
                }
              });
            }
            if (this.type === 'campaignAnalyticsList') {
              this.tableData.data.forEach((element) => {
                element.role === 'Authorised_Centre' ? element.role = 'Authorised Centre' : element.role = 'Preparation Centre';
              });
            }
            if (this.type === 'leadsList') {
              this.tableData.data.forEach((element) => {
                element.csvCentreRole === 'Authorised_Centre' ? element.csvCentreRole = 'Authorised Centre' : element.csvCentreRole = 'Preparation Centre';
              });
            }
            if (this.type === 'centresList') {
              this.tableData.data.forEach((element) => {
                if (element.qualificationCentre === 'Admin') {
                  element.qualificationAmount = 'Not Applicable';
                } else {
                  element.qualificationAmount = element.qualificationAmount + ' ' + 'INR';
                }

              });
            }
            if (this.type === 'qualificationStudentList') {
              this.tableData.data.forEach((ele) => {
                if (ele['candidateAuthCentreName']) {
                  ele.centreId = ele.candidateAuthCentreId;
                  ele.centreName = ele.candidateAuthCentreName;
                } else if (ele['candidatePrepCentreName']) {
                  ele.centreId = ele.candidatePrepCentreId;
                  ele.centreName = ele.candidatePrepCentreName;
                }
              });
            }
            if (this.type === 'examList') {
              this.tableData.data.forEach((ele) => {
                if (ele.qualificationCentre === 'Authorised_Centre') {
                  ele.qualificationCentre = 'Authorised Centre';
                } else if (ele.qualificationCentre === 'Preparation_Centre') {
                  ele.qualificationCentre = 'Preparation Centre';
                }
                ele && ele.prepCentre && ele.prepCentre.forEach(element => {
                  if (!ele.qualificationCentreName) {
                    ele.qualificationCentreName = element.centreName
                  } else {
                    ele.qualificationCentreName = ele.qualificationCentreName + ', ' + element.centreName;
                  }
                });
              });

            }
            if (this.type === 'EnrolmentListing') {
              this.tableData.data.forEach((ele) => {
                if (ele.candidateCentreRole === 'Authorised_Centre') {
                  ele.candidateCentreRole = 'Authorised Centre';
                } else if (ele.candidateCentreRole === 'Preparation_Centre') {
                  ele.candidateCentreRole = 'Preparation Centre';
                }
              });
            }
            if (this.type === 'bookedExamList') {
              this.tableData.data.forEach((ele) => {
                ele.qualificationAmount = ele.qualificationAmount + ' ' + 'INR';
              });
            }
            this.total = success.total;
            this.rerenderDataTable();
          } else {
            this.popToast('error', success.message);
            if(this.BsModalRef){
              this.BsModalRef.hide();
            }
          }
        });
      }
    }
  }
  // *************************************************************//

  /*************************************************************/
  // Api for change Status and Delete
  // parameters:
  // callAPi ==> name of Api to be called
  // data ==> data to be send
  /*************************************************************/
  CallAPIchangeStatusORDelete(callAPI, data) {
    this.commonService.callApi(callAPI, data, 'post', false, false).then((success: any) => {
      if (success.status === 1) {
        this.popToast('success', success.message);
        setTimeout(() => {
          this.callApiToGetData();
        });
      } else {
        this.popToast('error', 'Something Went Wrong!');
      }
    });
  }
  // *************************************************************//

  /********************************************************************************/
  // Api for downloading CSV or Excel file of datatable( with and without filter )
  /********************************************************************************/
  public download: any = {};
  public isFilter: boolean;
  CallAPIdownloadCSVandExcel(data) {
    data == 'csv' ? this.download.isCsv = true : this.download.isCsv = false;
    data === 'csv' ?
      this.commonService.callApi(this.CSV, this.download, 'post', false, false).then((success) => {
        if (success.status == 1) {
          window.open(success.data, '_blank');
        }
      })
      :
      this.commonService.callApi(this.EXCEL, this.download, 'post', false, false).then((success) => {
        if (success.status == 1) {
          window.open(success.data, '_blank');
        }
      });
  }
  // *************************************************************//

  /*************************************************************/
  // select users and change status
  /*************************************************************/
  public statusFormData = { status: '' };
  public statusList: any[];

  selectMultipleUser(data) {
    if (data.checkbox) {
      
      if(this.type !=='csvLeadsList1'){
        if (this.selectedUserList.length) {
          const x = this.selectedUserList.findIndex((o: any) => o._id === data._id);
          if (x === -1) {
            this.selectedUserList.push(data);
          }
        } else {
          this.selectedUserList.push(data);
        }
      } else if(this.type ==='csvLeadsList1'){
        if (this.selectedFileList.length) {
          const x = this.selectedFileList.findIndex((o: any) => o === data._id);
          if (x === -1) {
            this.selectedFileList.push(data._id);
          }
        } else {
          this.selectedFileList.push(data._id);
        }
      }
    } else {
      this.tableConfig.cols.forEach((element: any) => {
        element.checkbox = false;
      });
      if(this.type !=='csvLeadsList1'){
      const x = this.selectedUserList.findIndex((o: any) => o._id === data._id);
      if (x > -1) {
        const index = this.tableData.data.findIndex((i: any) => i._id === this.selectedUserList[x]._id);
        this.tableData.data[index].isSelected = false;
        this.selectedUserList.splice(x, 1);
      }
    } else   if(this.type ==='csvLeadsList1'){
      const x = this.selectedFileList.findIndex((o: any) => o === data._id);
      if (x > -1) {
        const index = this.tableData.data.findIndex((i: any) => i._id === this.selectedFileList[x]);
        this.tableData.data[index].isSelected = false;
        this.selectedFileList.splice(x, 1);
      }
    }
  }
  if (this.type === 'Sendnotificationlist') {
    data.isSelected = true;
  }
  }

  submitStatusForm(statusForm, statusFormData) {
    this.submitted = true;
    if (statusForm.valid && this.selectedUserList.length) {
      // DELETE
      if (statusFormData.status === 'Delete') {
        this.confirmpopToast('warning', 'Do you want to delete??').then((result) => {
          if (result.value === true) {
            const data = { [this.params.deleteParams]: this.selectedUserList };
            this.CallAPIchangeStatusORDelete(this.DeleteUser, data);
          }
        });
      }
      // DELETE
      // STATUS
      if (statusFormData.status === 'Inactive' || statusFormData.status === 'Active') {
        let data;
        statusFormData.status === 'Inactive' ?
          data = { [this.params.statusParams]: this.selectedUserList, status: false } :
          data = { [this.params.statusParams]: this.selectedUserList, status: true };
        this.CallAPIchangeStatusORDelete(this.ChangeStatus, data);
        const index = this.tableConfig.cols.findIndex((o: any) => o.type === 'multipleSelection');
        this.tableConfig.cols[index].checkbox = false;
      }
      // STATUS

    } else {
      this.popToast('error', 'Select Users To Modify');
    }
    statusForm.reset();
  }
  // *************************************************************//

  /*************************************************************/
  // Rerendering Datatable
  /*************************************************************/
  rerenderDataTable() {
    if (this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        if (this.tableData.data.length || this.APIparameters.searchText || this.APIparameters.filter || this.toggle) {
          dtInstance.destroy();
          this.dtTrigger.next();
        }
      });
    } else {
      this.dtTrigger.next();
    }
  }
  // *************************************************************//

  /*************************************************************/
  //    Sorting for user listing
  /*************************************************************/

  onSorted(event) {
    this.APIparameters.sort = {};
    event.sortDirection === 'desc' ? event.sortDirection = -1 : event.sortDirection = 1;
    this.APIparameters.sort[event.sortColumn.colFieldname] = event.sortDirection;
    this.callApiToGetData();
  }
  // *************************************************************//

  /***************************************************************/
  // Pagination //
  /***************************************************************/
  public itemsPerPageList = [5, 10, 25, 100];
  itemsPerPage = 10;

  pagination(e) {
    this.APIparameters.page = e.page;
    this.itemsPerPage = e.itemsPerPage;
    this.callApiToGetData();
    setTimeout(() => {
      if (this.selectedUserList.length) {
        this.selectedUserList.forEach((element: any) => {
          const x = this.tableData.data.findIndex((o: any) => element._id === o._id);
          if (x > -1) {
            this.tableData.data[x].checkbox = true;
          }
        });
      }
    }, 500);
  }

  selectItemPerPage(e) {
    this.APIparameters.pagesize = e;
    this.APIparameters.page = this.customPagination._page = 1;
    this.callApiToGetData();
  }
  /***************************************************************/
  isArray(data) {
    return Array.isArray(data);
  }
  /***************************************************************/
  // Perform Action: VIEW, EDIT, DELETE And SWITCH //
  /***************************************************************/

  performAction(k, i, payment?) {
    // VIEW action
    if (k.id === 1) {
      if (this.type === 'pclist') {
        this.router.navigate([this.URLConstants.VIEW_PC], { queryParams: { id: i._id, name: i.centreName } });
      } else if (this.type === 'CSVlist') {
        this.router.navigate([this.URLConstants.STUDENT_LIST], { queryParams: { id: i._id, name: i.csvFileName, categoryType: i.categoryName } });
      } else if (this.type === 'leadsList') {
        this.router.navigate([this.URLConstants.MyLeadsStudents], { queryParams: { id: i._id, name: i.csvFileName, categoryType: i.categoryName } });
      }

      else if (this.type === 'Templatelist' || this.type === 'Sendnotificationlist' || this.type === 'seletedLeadData') {
        this.bsModalRef = this.modalService.show(ViewNotificationComponent,
          { class: 'modal-md modal-dialog-centered', initialState: { data: i } });
      } else if (this.type === 'examList') {
        if (this.loginData.categoryType === 'School' || this.loginData.categoryType === 'Institutional Centres') {
          this.router.navigate([this.URLConstants.DAYVIEW_LIST], { queryParams: { id: i._id, name: i.qualificationName, role: i.qualificationCentre, qualificationGrade: i.qualificationGrade } });
        } else if (this.loginData.categoryType === 'Organisation') {
          this.router.navigate([this.URLConstants.DAYVIEW_LIST], { queryParams: { id: i._id, name: i.qualificationName, role: i.qualificationCentre, qualificationStream: i.qualificationStream } });
        } else {
          this.router.navigate([this.URLConstants.DAYVIEW_LIST], { queryParams: { id: i._id, name: i.qualificationName, role: i.qualificationCentre } });

        }
      } else if (this.type === 'centresList') {
        if (i.qualificationCentre !== 'Admin') {
          if (payment === '') {
            this.router.navigate([this.URLConstants.BOOKEXAM, i._id, i.qualificationCentreId, i.qualificationCentreId]);
          }
          else {
            this.router.navigate([this.URLConstants.EXAMDETAIL, i.qualificationCentreId, payment]);

          }
        } else {
          this.popToast('info', 'Please Contact Preparation Centre');
        }

      }
      else if (this.type === 'bookedExamList') {
        this.router.navigate([this.URLConstants.EXAMDETAIL, i.scheduledQualificationId, i.scheduledQualificationId])
      }
    } else if (k.id === 2) {
      if (this.type === 'pclist') {
        this.router.navigate([this.URLConstants.ADD_EDIT_PC, i._id]);
      } else if (this.type === 'CsvUserlist') {
        this.bsModalRef = this.modalService.show(EditStudentDetailsComponent,
          { class: 'modal-lg modal-dialog-centered', initialState: { data: i } });
      } else if (this.type === 'Templatelist') {
        this.router.navigate([this.URLConstants.ADD_EDIT_TEMPLATE, i._id]);
      } else if (this.type === 'Sendnotificationlist') {
        this.router.navigate([this.URLConstants.ADD_EDIT_TEMPLATE, i._id]);
      } else if (this.type === 'examList') {
        this.router.navigate([this.URLConstants.ADD_EDIT_EXAM, i._id]);
      } else if(this.type=='walkinList'){
        this.bsModalRef = this.modalService.show(EditPaymentComponent,
          { class: 'modal-md modal-dialog-centered', initialState: { data: i } });
      }
      else if (k.id == 2) {
        this.router.navigate([this.URLConstants.EDIT_QUALIFICATION, i._id]);
      }
    } else if (k.id === 3) {
      this.confirmpopToast('warning', 'Do you want to delete??').then((result) => {
        if (result.value === true) {
          const dataObj = {
            [this.params.deleteParams?this.params.deleteParams:'_id']: i._id,
          };
          this.CallAPIchangeStatusORDelete(this.DeleteUser, dataObj);
        }
      });
    }
  }
  // SWITCH action
  changeStatus(d) {
    setTimeout(() => {
      const dataObj = {
        [this.params.statusParams]: [d._id],
        status: d.status,
      };
      this.CallAPIchangeStatusORDelete(this.ChangeStatus, dataObj);
    });
  }
  /***************************************************************/

  /***************************************************************/
  // change visibility of columns //
  /***************************************************************/

  public columnVisibleArr: any[] = [];

  tooglecolumnVisibilityFilter() {
    this.columnVisibilityOptions = !this.columnVisibilityOptions;
    this.filterColumnOption = false;
  }

  CallAPIchangeColumnVisibility(tableConfigCols) {
    tableConfigCols.forEach((element: any) => {
      if (element.columnVisibility) {
        const data = { key: element.colFieldname, status: element.isVisible };
        this.columnVisibleArr.push(data);
      }
    });
    const data = { columns: this.columnVisibleArr };
    this.commonService.callApi(this.colVisible, data, 'post', false, false).then((success: any) => {
      success.status === 1 ? this.popToast('success', 'Updated Successfully') : this.popToast('error', success.message);
    });
    this.columnVisibleArr = [];
    this.callApiToGetData();
  }

  selectAllColumnVisible() {
    this.tableConfig.cols.forEach((element: any) => {
      element.isVisible = true;
    });
  }

  resetColumnVisible() {
    this.callApiToGetData();
  }
  /***************************************************************/

  /***************************************************************/
  // filter //
  /***************************************************************/

  filterlist: any[] = [];
  public filter = [];
  public filterResponse: any = {
    page: 1,
    pagesize: 20,
  };

  toggleFilterColumn() {
    this.filterColumnOption = !this.filterColumnOption;
    this.columnVisibilityOptions = false;
  }

  getFilterlistData(colName, event?) {

    this.filterlist = [];

    if (this.type === 'Sendnotificationlist') {
      this.filterResponse.column = colName;
      this.filterResponse.isAdminData = true;
      this.filterResponse['filter'] = [{ approvalStatus: ['Approved'] }];
      // if (this.loginData.role === 'Authorised_centre') {
      //   this.filterResponse.createdBy = this.loginData._id;
      // }
      // else {
      //   this.filterResponse.createdBy = this.loginData.parentAuthorizationCentre;
      // }
      // this.filterResponse.categoryType = this.loginData.categoryId;
      if (event.target.value) {
        this.filterResponse['searchInFilter'] = event.target.value;
      }

    } else if (this.type === 'Templatelist') {
      this.filterResponse.column = colName;
      this.filterResponse.createdBy = this.loginData._id;

      this.filterResponse['searchInFilter'] = event.target.value;
    } else if (this.type === 'CsvUserlist') {
      this.filterResponse.column = colName;
      this.filterResponse.role = this.loginData.role;
      this.filterResponse._id = this.paramId;

      this.filterResponse['searchInFilter'] = event.target.value;
    } else if (this.type === 'CSVlist') {
      if (colName === 'year') {
        colName = 'csvUploadedYear';
      }
      this.filterResponse.column = colName;
      this.filterResponse.role = this.loginData.role;
      this.filterResponse._id = this.loginData._id;

      this.filterResponse['searchInFilter'] = event.target.value;
    } else if (this.type === 'pclist') {
      this.filterResponse.column = colName;
      this.filterResponse.role = 'Preparation_Centre';
      this.filterResponse._id = this.loginData._id;
      this.filterResponse['searchInFilter'] = event.target.value;
    } else if (this.type === 'Communicationlist' || this.type === 'campaignAnalyticsList') {
      if (this.type === 'campaignAnalyticsList' && colName === 'role') {
        colName = 'prepCentreName';
      }
      this.filterResponse.column = colName;
      this.filterResponse.isAnalytic = true;
      this.filterResponse.role = this.loginData.role;
      this.filterResponse._id = this.loginData._id;

      this.filterResponse['searchInFilter'] = event.target.value;

    } else if (this.type === 'walkinList') {
      this.filterResponse.column = colName;
      this.filterResponse.isWalkin = true;

      this.filterResponse['searchInFilter'] = event.target.value;

    }
    else if (this.type === 'leadsList') {
      this.filterResponse.column = colName;
      this.filterResponse['searchInFilter'] = event.target.value;
      this.filterResponse['_id'] = this.loginData._id;

    }
    else if (this.type === 'LeadsUserlist') {
      this.filterResponse.column = colName;
      this.filterResponse.isLeadUser = true;
      this.filterResponse['searchInFilter'] = event.target.value;
      this.filterResponse['_id'] = this.tableSetupData.paramId;
    }
    else if (this.type === 'RegisteredCandidates') {
      this.filterResponse.column = `${colName}`;
      this.filterResponse.isUserAuth = true;
      this.filterResponse.isAllUserData = true;
      this.filterResponse['searchInFilter'] = event.target.value;
    }
    else if (this.type === 'qualificationStudentList') {
      this.filterResponse.column = `candidateData.${colName}`;
      this.filterResponse.qualificationId = this.paramId;
      this.filterResponse['searchInFilter'] = event.target.value;
      if (this.tableSetupData.qualificationGrade) {
        this.filterResponse.qualificationGrade = this.tableSetupData.qualificationGrade;
      }
    }
    else {
      this.filterResponse = {
        page: 1,
        pagesize: 20,
        column: colName,
      };
      this.filterResponse['searchInFilter'] = event.target.value;
    }
if(this.filterResponse.column === 'candidateData.qualificationName'){
  this.filterResponse.column = 'candidateData.userQualficationName' 
}
    if (colName === 'csvQualificationName' || colName === 'qualification' || (colName === 'qualificationName')) {
      this.CallAPIgetFilterList(this.getFilterlist, this.filterResponse);
    
      // this.filter.forEach((data) => {
      //   for (let key in data) {
      //     if (key === 'categoryName' && this.filterResponse.column === 'csvQualificationName') {
      //       this.filterResponse['filter'] = [{ categoryName: data[key] }];
      //       this.CallAPIgetFilterList(this.getFilterlist, this.filterResponse);
      //     } else if (key === 'categoryType' && (this.filterResponse.column === 'qualification' || (this.filterResponse.column === 'qualificationName'))) {
      //       this.filterResponse['filter'] = [{ categoryType: data[key] }];
      //       this.CallAPIgetFilterList(this.getFilterlist, this.filterResponse);
      //     } else if (key === 'userCategoryType' && (this.filterResponse.column === 'candidateData.qualificationName' || this.filterResponse.column === 'candidateData.userQualficationName' || (this.filterResponse.column === 'qualificationName'))) {
      //       this.filterResponse['filter'] = [{ 'candidateData.userCategoryType': data[key] }];
      //       this.CallAPIgetFilterList(this.getFilterlist, this.filterResponse);
      //     }
      //   }
      // });
    }
    else if ((colName == 'categoryType') || colName == 'categoryName') {
      this.filterlist = [];
      this.getCategories();
    }
    else if (this.type === 'Sendnotificationlist') {
      this.CallAPIgetFilterList(this.getFilterlist, this.filterResponse);

    }
    else {
      if(this.filterResponse.filter){ delete this.filterResponse.filter };
      this.CallAPIgetFilterList(this.getFilterlist, this.filterResponse);
    }
    if (colName === 'status') {
      this.filterlist = ['Active', 'Inactive'];
    };
  }
  getCategories() {
    this.commonService.callApi('/admin/getCategory', '', 'post', false, false).then((success) => {
      if (success.status == 1) {
        this.filterlist = [];
        success.data.categoryData.forEach(element => {
          this.filterlist.push(element.categoryType);
        });;
      }
    });
  }
  CallAPIgetFilterList(method, data) {
    if (data.column == "csvQualificationDate") {
      data.column = 'csvQualificationExamData'
    }

    this.commonService.callApi(method, data, 'post', false, false).then((success) => {
      if (success.status === 1) {
        if (this.type === 'campaignAnalyticsList' && data.column === 'prepCentreName') {
          success.data.listing.forEach((ele) => {

            this.filterlist.push(ele.prepCentreName);
            // this.filterlist = [...this.filterlist];
          });
        } else if (this.type === 'CSVlist' && data.column === 'csvCentreRole') {
          success.data.listing.forEach((ele) => {
            if (ele === 'Preparation_Centre') {
              this.filterlist.push('Preparation Centre');
            } else if (ele === 'Authorised_Centre') {
              this.filterlist.push('Authorised Centre');
            }
            // this.filterlist = [...this.filterlist];
          });
        } else if (this.type === 'RegisteredCandidates' && data.column === 'centreDataRole') {
          success.data.listing.forEach((ele) => {
            if (ele === 'Preparation_Centre') {
              this.filterlist.push('Preparation Centre');
            } else if (ele === 'Authorised_Centre') {
              this.filterlist.push('Authorised Centre');
            }
            // this.filterlist = [...this.filterlist];
          });
        }
        else if (this.type === 'leadsList' && data.column === 'csvCentreRole') {
          success.data.listing.forEach((ele) => {
            if (ele === 'Preparation_Centre') {
              this.filterlist.push('Preparation Centre');
            } else if (ele === 'Authorised_Centre') {
              this.filterlist.push('Authorised Centre');
            }
            // this.filterlist = [...this.filterlist];
          });
        }
        else {
          this.filterlist = success.data.listing;
        }
      }
    });
  }

  selectFilter(fieldName, event) {

    if (fieldName === 'status') {
      if (event === 'Active') {
        this.modifyFilterArr(fieldName, [true]);
      } else if (event === 'Inactive') {
        this.modifyFilterArr(fieldName, [false]);
      } else {
        this.modifyFilterArr(fieldName, [true, false]);
      }
    } else {
      this.modifyFilterArr(fieldName, event);
    }
  }

  modifyFilterArr(fieldName, event) {
    if ((fieldName === 'categoryType' || fieldName === 'categoryName') && event.length) {
      this.tableConfig.cols.forEach((element1) => {
        if (element1.colName === 'Qualification') {
          element1['value'] = [];
        }
      });
    }
    const index = this.filter.findIndex((o) => Object.keys(o).includes(fieldName));
    if (index > -1) {
      this.filter[index][fieldName] = event;
    } else {
      this.filter.push({ [fieldName]: event });
    }
    if (!event.length) {
      this.tableConfig.cols.forEach((element1) => {
        if (element1.colName === 'Qualification') {
          element1['value'] = [];
        }
      });
      this.filter.splice(index, 1);
    }
  }

  applyFilter() {

    if (this.filter.length) {
      this.APIparameters.page = 1;
      if (this.type === 'Sendnotificationlist') {
        const index = this.filter.findIndex((o) => Object.keys(o).includes('approvalStatus'));
        if (index > -1) {
        } else {
          this.filter.push({ ['approvalStatus']: ['Approved'] });
        }
      }
      this.APIparameters.filter = JSON.parse(JSON.stringify(this.filter));
      if (this.type === 'campaignAnalyticsList') {
        // this.APIparameters.role = 'Authorised_Centre';
      }
      this.APIparameters.filter.forEach((data, index) => {
        for (let key in data) {
          if (key === 'authCentreName' && this.type !== 'leadsList') {
            data['authCentreData.centreName'] = [];
            data['authCentreData.centreName'] = data[key];
            delete data[key];
          } else if (key === 'role' && data[key].length) {
            data['centreName'] = [];
            data['centreName'] = data[key];
            delete data['role'];

            // this.APIparameters.role = 'AuthInPreparation_Centre';
          } else if (key === 'csvCentreRole' && this.type === 'CSVlist') {
            let data1 = [];
            data.csvCentreRole.forEach((e) => {
              if (e === 'Preparation Centre') {
                data1.push('Preparation_Centre');
              } else if (e === 'Authorised Centre') {
                data1.push('Authorised_Centre');
              }
            });
            data.csvCentreRole = [];
            data['csvCentreRole'] = data1;
          } else if (key === 'year') {
            let videoId = data[key];
            let id = [];
            videoId.forEach((e) => {
              id.push(e.toString().split(' ')[0]);
            });
            data['csvUploadedStartYear'] = id;
            delete data[key];
          }
          else if (this.tableSetupData.type == 'qualificationStudentList') {
            for (let key in data) {
              data['candidateData.' + key] = [];
              data['candidateData.' + key] = data[key];
              delete data[key];
            }
          }
          else if (key === 'csvCentreRole' && this.type === 'leadsList') {
            let filterList = [];
            data[key].forEach(ele => {
              (ele == 'Authorised Centre' ? ele = 'Authorised_Centre' : ele = 'Preparation_Centre')
              filterList.push(ele);
            })
            data['csvCentreRole'] = filterList;
          }
          else if (key === 'centreDataRole' && this.type === 'RegisteredCandidates') {
            let filterList = [];
            data[key].forEach(ele => {
              (ele == 'Authorised Centre' ? ele = 'Authorised_Centre' : ele = 'Preparation_Centre')
              filterList.push(ele);
            })
            data['centreDataRole'] = filterList;
          }
        }
      });
      this.callApiToGetData();
    } else {
      setTimeout(() => {
        this.popToast('error', 'Please Choose Atleast One Filter');
      }, 200);
    }
  }

  resetFilter() {
    this.tableConfig.cols.forEach((element) => {
      element.value = [];
    });
    this.filter = [];
    this.type === 'Sendnotificationlist' ?
      this.APIparameters.filter = [{ approvalStatus: ['Approved'] }] :
      this.APIparameters.filter = []
    this.callApiToGetData();
  }

  /***************************************************************/

  /***************************************************************/
  // SAVE and DELETE FILTER //
  /***************************************************************/

  openSaveFilterModal(saveFilters: TemplateRef<any>) {
    this.BsModalRef = this.modalService.show(saveFilters);
  }

  public filterToSave = {};

  submitSaveFilter(saveFilterForm, filterToSave) {
    this.tableConfig.cols.forEach((element) => {
      element.value = [];
    });
    this.submitted = true;
    if (saveFilterForm.valid && this.filter) {
      const filter = {
        filterName: filterToSave.filterName,
        filter: this.filter,
      };
      this.filter = [];
      this.commonService.callApi(this.saveFilter, filter, 'post', false, false).then((success) => {
        if (success.status === 1) {
          this.popToast('success', 'Filter is saved Successfully');
          this.CallAPIgetSavedFilter();
        }
      });
      this.submitted = false;
      this.BsModalRef.hide();
    } else {
      this.popToast('error', 'Select Values to save');
    }
    saveFilterForm.reset();
  }

  public dataOfSaveFilter: any;

  CallAPIgetSavedFilter() {
    if (this.getFilter) {
      this.commonService.callApi(this.getFilter, '', 'get', false, false).then((success: any) => {
        if (success.status === 1) {
          this.dataOfSaveFilter = success.data;
        }
      });
    }
  }

  showSaveFilter(i) {
    this.APIparameters.filter = i.filter;
    this.callApiToGetData();
  }

  CallAPIdeleteSaveFilter(i) {
    this.commonService.callApi(this.deleteFilter + i._id, '', 'delete', false, false).then((success: any) => {
      if (success.status === 1) {
        this.popToast('success', success.message);
        this.dataOfSaveFilter = {};
      }
    });
    this.CallAPIgetSavedFilter();
  }
  /***************************************************************/

  /***************************************************************/
  // SELECT ALL USERS IN TABLE //
  /***************************************************************/
  selectAllUser(i) {
    if (i.checkbox === true) {
      this.tableData.data.forEach((element: any) => {
        element.checkbox = true;
        element.isSelected = true;
        if(this.type!=='csvLeadsList1'){
          if (this.selectedUserList.length) {
            const x = this.selectedUserList.findIndex((o) => o._id === element._id);
            if (x === -1) {
              this.selectedUserList.push(element);
            }
          } else {
            this.selectedUserList.push(element);
          }
        } else if(this.type==='csvLeadsList1'){
          const x = this.selectedFileList.findIndex((o) => o === element._id);
          if (x === -1) {
            this.selectedFileList.push(element._id);
          }
        } else {
          this.selectedFileList.push(element._id);
        
        }
       
      });
    } else {
      this.tableData.data.forEach((element: any) => {
        element.checkbox = false;
        element.isSelected = false;
        if(this.type=='csvLeadsList'){
        const x = this.selectedUserList.findIndex((o: any) => o._id === element._id);
        if (x > -1) {
          this.selectedUserList.splice(x, 1);
        }
        } else{
          const x = this.selectedFileList.findIndex((o: any) => o === element._id);
          if (x > -1) {
            this.selectedFileList.splice(x, 1);
          }
        }
      });
    }
  }
  /***************************************************************/
  UnselectUsers() {
    this.tableConfig.cols.map((element: any) => {
      if (element.type === 'multipleSelection') {
        element.checkbox = false;
      }
    });
    this.tableData.data.forEach((element: any) => {
      element.checkbox = false;
      element.isSelected = false;
      const x = this.selectedUserList.findIndex((o: any) => o._id === element._id);
      if (x > -1) {
        this.selectedUserList.splice(x, 1);
      }
    });
  }
  /***************************************************************/
  // For sending the notification
  /***************************************************************/
  sendNotification(data, type) {
    if (this.type === 'LeadsUserlist' || this.type === 'leadsList') {
      const obj: any = {};
      obj.id = data._id;
      obj.type = data.csvCentreRole === 'Authorised Centre' ? 'ac' : 'pc';
      this.commonService.callApi(this.callAPIConstants.LeadSendMail, obj, 'post', false, false).then((response: any) => {
        if (response.status === 1) {
          this.popToast('success', response.message);
        } else {
          this.popToast('error', response.message);
        }
      });
    }
    else if (type === 'csvLeadsList') {
      let ids = this.selectedUserList.map((obj) => {
        return obj._id
      });
      this.setToken('selectedLeadCSVList', JSON.stringify(ids));
      let id = this.selectedUserList[0].csvQualification;
      // this.receivedData.emit(data);
      this.router.navigate([this.URLConstants.SENDNOTIFICATION, id]);
      // this.BsModalRef = this.modalService.show(SelectCsvLeadsComponent,
      //   { ignoreBackdropClick: true, keyboard: false, class: 'modal-lg modal-dialog-centered', initialState: { data: this.selectedUserList[0] } });

    }
    else {
      this.BsModalRef = this.modalService.show(SendStudentNotificationsComponent,
        { ignoreBackdropClick: true, keyboard: false, class: 'modal-lg modal-dialog-centered', initialState: { data: data } });
      // if(this.loginData.role !=='Preparation_Centre'){
      // } else {
      //   const dataSending: any = {
      //     notificationData: [],
      //   };
      //   this.selectedUserList.forEach((x: any) => {
      //     dataSending.notificationData.push({
      //       templateId: x._id,
      //       qualificationId: x.qualificationId,
      //     });
      //   });
      //   this.commonService.callApi(this.callAPIConstants.massEmailSMSNotificationSend, dataSending, 'post', false, false).then((success: any) => {
      //     if (success.status === 1) {
      //       this.popToast('success', success.message);
      //       this.tableData.data.forEach((element: any) => {
      //         element.checkbox = false;
      //         element.isSelected = false;
      //         const x = this.selectedUserList.findIndex((o: any) => o._id === element._id);
      //         if (x > -1) {
      //           this.selectedUserList.splice(x, 1);
      //         }
      //       });
      //     } else {
      //       this.popToast('error', success.message);
      //     }
      //   });
      // }
     
    }
  }
  /***************************************************************/

  /****************************************************************************
  @Purpose : To get search list.
  /****************************************************************************/
  searchLisitng(value): Observable<any> {
    if (value.length) {
      if (value.length >= 3) {
        this.APIparameters['page'] = 1;

        this.APIparameters['searchText'] = value;
        this.callApiToGetData();
        return of([]);
      }
    } else {
      delete this.APIparameters.searchText;
      this.callApiToGetData();
      return of([]);
    }
  }
  typeaheadOnBlur(event: any): void {
    this.setFocus = false;
  }
  /***************************************************************
  @Purpose : To Get Dynamic FilterValues.
  /***************************************************************/
  filterValue(data) {
    this.APIparameters.filter = data;
  }
  ngOnDestroy() {
    if (this.filterAppliedBroadcaster) {
      this.filterAppliedBroadcaster.unsubscribe();
    }
    if (this.dayViewBroadcastrer) {
      this.dayViewBroadcastrer.unsubscribe();
    }
    if (this.csvUserBroadcaster) {
      this.csvUserBroadcaster.unsubscribe();
    }
    if (this.offlinePaymentBroadcaster) {
      this.offlinePaymentBroadcaster.unsubscribe();
    }
  }
  public payementdata: any;
  public payment: any = {};
  //Approve the payment Status model
  approvedPaymentModel(template: TemplateRef<any>, data) {
    this.modalRef = this.modalService.show(OfflinePaymentComponent,
      { class: 'modal-md modal-dialog-centered', ignoreBackdropClick: true, initialState: { payementdata: data } });
    this.payementdata = data;

  }

  //approve the payment status
  public toggle: boolean = false;
  approvedPaymentStatus(payment) {
    this.approved = true
    if (payment.status) {
      let data = {
        bookedPaymentType: payment.status,
        _id: this.payementdata.bookExamdataId
      }
      this.commonService.callApi(this.callAPIConstants.ApprovePayementStatus, data, 'post', false, false).then((success: any) => {
        if (success.status === 1) {
          this.popToast("success", success.message)
          this.payment = {};
          this.approved = false;
          this.modalRef.hide();
          this.toggle = true;
          this.tableConfig.noText = 'testing';
          if (this.toggle) {
            this.callApiToGetData();

          }
        }
        else {
          this.popToast('error', success.message)
        }
      })
    }
  }


  public selected = -1;
  public seletedCSVTemplate: any = [];
  public seletedUserIds: any;
  selectSingleUser(data, event) {
    if (event.target.checked) {
      this.seletedCSVTemplate = [];
      this.seletedCSVTemplate.push(data);
      this.seletedUserIds = JSON.parse(this.getToken('selectedLeadCSVList'));
    } else {
      if (this.seletedCSVTemplate.length) {
        this.seletedCSVTemplate.splice(0, 1);
      }
    }
  }
  sendSeletedLeadNotification() {
    let data = JSON.parse(this.getToken('data'))
    let finalData = {
      ids: this.seletedUserIds?this.seletedUserIds:this.selectedFileList,
      templateId: this.type!=='csvLeadsList1'?this.seletedCSVTemplate[0]._id:this.params.statusParams,
      // role: data.role === 'Authorised_Centre' ? 'ac' : 'pc'
    }
    
    this.commonService.callApi('/csvleadfile/sendMultipleMail', finalData, 'post', false, false).then((success: any) => {
      if (success.status === 1) {
        if(this.type =='csvLeadsList'){
          this.router.navigate([this.URLConstants.SELECTLEADFILE]);
        } else{
          this.BsModalRef.hide();
        }
        this.removeToken('selectedLeadCSVList');
        this.popToast('success', success.message);
      } else{
        this.popToast('error', success.message);
      }
    });
  }
}