export const callAPIConstants = {
    StudentRegister: '/students/register',
    LeadsRegister: '/csvleadfile/studentLeadRegister',
    SendOTP: '/users/sendOtp',
    VerifyOTP: '/users/otpVerification',
    Login: '/users/login',
    ForgotPassword: '/users/forgotPasswordType',
    ResetPassword: '/users/resetPasswordType',
    AdminSetPassword: 'admin/setPassword',
    ACPCSetPassword: '/authcentre/resetPassword',
    StudentProfileUpdate: '/students/updateProfile',
    PCProfileUpdate: '/prepcentre/updateProfile',
    ACProfileUpdate: '/authcentre/updateProfile',
    CSVUpload: '/csvfile/uploadCsvFile ',
    LeadUpload: '/csvleadfile/uploadCsvLeadFile ',
    PcListing: '/authcentre/getPrepCentreList',
    PcFilterList: '/authcentre/getColumnValues',
    pcUpdate: '/authcentre/editPrepCentreProfile',
    getSampleCSVList: '/csvfile/sampleCsvListing',
    CsvListing: '/csvfile/getCsvLists',
    LeadsListing: '/csvleadfile/getCsvLeadFileLists',
    LeadsUsersListing: '/csvleadfile/getCsvLeadUserLists',
    CsvFilterList: '/csvfile/getColumnValues',
    LeadsFilterList: '/csvleadfile/getColumnValues',
    CsvUserListing: '/csvfile/getCsvUsers',
    CsvUserFilterList: '/users/getColumnValues',
    CsvUserUpdate: '/csvfile/updateCsvUsers',
    SearchCsvUser: '/csvfile/csvUserSearch',
    CommunicationListing: '/notification/notificationCommunication',
    CommunicationFilterList: '/notification/getColumnValues',
    TemplateListing: '/notification/listTemplate',
    CSVLeadUsers: '/csvleadfile/getLeadCsvBasedOnCatAndQualification',
    seletedLeadData: '/notification/templatesBeasedOnQualification',
    SendNotificationTemplate: '/notification/listApproveTemplate',
    NotificationFilterList: '/notification/getColumnValues',
    AddEditTemplate: 'notification/addUpdateTemplate',
    QualificationListByLoginId: '/admin/qualificationListBasedOnLogin',
    AdminFileUpload: '/admin/fileUpload',
    DownloadCsv: '/csvfile/sampleCsvDownload',
    DownloadLeads: '/csvleadfile/downloadLeadFile',
    PrepProfileUpload: '/prepcentre/fileUpload',
    AuthProfileUpload: '/authcentre/fileUpload',
    StudentProfileUpload: '/students/fileUpload',
    AddUpdateTemplate: '/notification/addUpdateTemplate',
    StudentJourney: '/csvfile/getStudentDetails',
    massEmailNotificationList: '/notification/massEmailNotificationAnalytics',
    massEmailNotificationAnalytics: '/notification/analyticsData',
    massEmailSMSNotificationSend: '/notification/massEmailSmsNotification',
    getCategoryList: '/admin/getCategory',
    getQualificationBasedOnCatgory: '/authcentre/getQualificationBasedCategory',
    getQualificationBasedCategory: '/prepcentre/getQualificationBasedCategory',
    getQualificationList: '/admin/getQualification',
    pcAddByAc: '/authcentre/addPrepCentres',
    getPcProfileByAc: '/authcentre/getPrepProfile',
    MonthExamListing: '/exam/examLists',
    examListing: '/exam/examLists',
    AddExam: '/exam/addExams',
    scheduleExam: '/scheduleExam/addExam',
    updateScheduleExam: '/scheduleExam/editExam',
    UpdateExam: '/exam/editExams',
    QualificationStudentList: '/exam/getExamStudents',
    ScheduledExamDetailById: '/exam/oneExamDetails',
    FilterForScheduledExamDetailById: '/exam/getStudentColumnValues',
    WalkInListing: '/authcentre/paidRegisteredStudentListing',
    WalkInFilterList: '/exam/getColumnValues',
    RegisteredFilterList: '/students/getColumnValues',
    // EnrolmentListing: '/exam/enrolledStudents',
    CandidatesToBeregisteredListing: '/exam/enrolledStudents',
    BookingCentresListing: '/students/allQualificationForBooking',
    BookExam: '/students/bookExam',
    ExamSummary: '/students/bookExamSummary',
    CmsDetail: '/cmsDetail',
    PrepCentreListUnderAc: '/authcentre/getPrepCentresUnderAc',
    BookedExamListing: '/students/bookedExamList',
    ResgisteredCandiatesList: '/authcentre/studentListing',
    searchRegisteredCandidates: '/admin/studentParentsSearch',
    getPCBasedOnQualification: '/authcentre/getPCBasedOnQualification',
    getExamDatesBasedOnQualification: '/scheduleExam/getExamDatesBasedOnQualification',
    getExamDatesNew:'/exam/getExamDates',
    QualificationListing: '/authcentre/qualificationFixedPriceListing',
    AddQualification: '/authcentre/addQualificationPrice',
    updateQualification: '/',
    getQualificationDetails: '/authcentre/qualificationpricedetail/',
    UpdateQualification: '/authcentre/editQualificationPrice',
    QualificationgetColumnValues: '/qualificationFixedPrice/getColumnValues',
    ApprovePayementStatus: '/exam/addPaymentStatus',
    LeadSendMail: '/csvleadfile/sendMail'
};