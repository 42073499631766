import { Component, ElementRef, EventEmitter, Injector, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '../../common/commonComponent';

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
})
export class ProfileDetailsComponent extends BaseComponent implements OnInit {
  @Input() user: any = {};
  @Output() profileInfoChange = new EventEmitter();
  @ViewChild('personalInfoForm') personalInfoForm: ElementRef;
  subscriber: any;
  submitted: boolean = false;
  acceptPaymentType: any;
  public paymentArr: any[] = [{ name: 'Offline' }, { name: 'Online' }];
  public locations: any = {};
  public countryArr: Array<any> = [];
  public stateArr: Array<any> = [];
  public cityArr: Array<any> = [];
  locationEvent: any;
  countryId;
  constructor(inj: Injector, public zone: NgZone) {
    super(inj);
    //Form submission event
    if (this.loginData.isProfileUpdated || this.loginData.role === 'Student/Parents') {
      this.subscriber = this.broadcaster.on<boolean>('submitted').subscribe((success) => {
        this.submitted = success;
        this.profileInfoChange.emit({ data: this.user, form: this.personalInfoForm['valid'] });
      });
    }
  }

  ngOnInit() {
    if (!this.loginData.countryId) {
      this.loginData.countryId = '5df09de5cb5c1c74bbd4452a';
    }
    if (this.user.acceptPaymentType) {
      this.paymentArr.forEach((x) => {
        this.user.acceptPaymentType.forEach((y) => {
          if (x.name === y) {
            x.checked = true;
          }
        });
      });
    }
    this.locations = { isCountry: true };
    this.getLocations();
    if (this.loginData.countryId) {
      this.getState({ _id: this.loginData.countryId });
      this.getCity(this.loginData.state);
    }

    this.user.state = this.loginData.state;
    this.user.city = this.loginData.city;
  }

  /**********************************************************************
      @PURPOSE      :  For Getting state,City and Country from selected Address
      @PARAMETERS   : {
          addressObj : addressObj which contains the selected Address
      }
  /**********************************************************************/
  placeChanged(addressObj) {
    this.zone.run(() => {
      this.user.address = addressObj.address;
      this.user.city = addressObj.city;
      this.user.state = addressObj.state;
      this.user.country = addressObj.country;
    });
  }
  /**********************************************************************/

  OnDestroy() {
    if (this.loginData.isProfileUploaded) {
      // destroy the instance of subscriber
      this.subscriber.unsubscribe();
    }
  }

  /****************************************************************************/
  // tslint:disable-next-line: cognitive-complexity
  selectedCheckbox(data) {
    let count = 0;
    let index;
    for (let k = 0; k < this.user.acceptPaymentType.length; k++) {
      if (data.name === this.user.acceptPaymentType[k]) {
        count++;
        index = k;
      }
    }
    if (count) {
      this.user.acceptPaymentType.splice(index, 1);
    } else {
      this.user.acceptPaymentType.push(data.name);
    }
  }
  /*************************************************************/
  getLocations() {
    this.commonService.callApi('/admin/getCountryStateCity', this.locations, 'post').then(success => {
      if (success.status == 1) {
        this.countryArr = success.data;
      }
    })
  }
  getState(event, change?) {
    if (event._id) {
      this.user.countryId = event._id;
      this.locationEvent = event;
    }
    else {
      this.user.countryId = event;
    }
    if (change) {
      delete this.user.state;
      delete this.user.city;
      if (this.cityArr.length) {
        this.cityArr = [];
      }
    }
    // if (this.centre.state && event._id) {
    //   this.centre.state = '';
    // }
    // if (this.centre.city && event._id) {
    //   this.centre.city = '';
    // }

    this.locations = {};
    this.locations._id = this.user.countryId;
    this.locations.isState = true;
    this.commonService.callApi('/admin/getCountryStateCity', this.locations, 'post').then(success => {
      if (success.status == 1) {
        this.stateArr = success.data;
      }
    })
  }
  getCity(name, change?) {
    // if (this.centre.city && this.locationEvent._id) {
    //   this.centre.city = '';
    // }
    if (change) {
      delete this.user.city;
    }
    this.locations = {};
    this.locations.state = name;
    this.locations.isCity = true;
    this.locations._id = this.user.countryId;
    this.commonService.callApi('/admin/getCountryStateCity', this.locations, 'post').then(success => {
      if (success.status == 1) {
        this.cityArr = success.data;
      }
    })
  }
}
