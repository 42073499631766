import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../../common/commonComponent';
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-edit-payment',
  templateUrl: './edit-payment.component.html',
  styles: []
})
export class EditPaymentComponent extends  BaseComponent implements OnInit {
  user: any = {};
  userData: any = {};
  submitted: boolean = false;
  public data;
  public qualificationDatesArr: any =[];
  oldScheduleExamId: any;

  constructor(inj: Injector) {
    super(inj);
  }

  ngOnInit() {
    this.user = Object.assign({}, this.data);
this.getQualificationDate();
this.oldScheduleExamId=this.data.bookedForQualification;
this.user.scheduleExamId=this.data.bookedForQualification;
  }
  getQualificationDate() {
    let apiData: any = {};
    apiData.qualification = this.user.qualification;
    apiData.authcentreId = this.user.authCentreId;
    apiData.city = this.user.city;
    this.commonService.callApi(this.callAPIConstants.getExamDatesBasedOnQualification, apiData, 'post', true).then(res => {
      if (res.status === 1) {
        this.qualificationDatesArr = res.data;
      }
    })
  }
  /**********************************************************************
       @PURPOSE      :  For calling UpdateCsvUserDetails API
       @PARAMETERS   : {
           form: Form, It is to know Form valid or not
           user: Object, User data
       }
   /**********************************************************************/
  UpdateCsvUserDeetails(form, user) {
    this.submitted = true;
    if (form.valid) {
      let data = {
        studentId: this.data._id,
        oldScheduleExamId:this.oldScheduleExamId,
        scheduleExamId:this.user.scheduleExamId,
        bookedForDate:this.user.bookedForDate
      };
      this.commonService.callApi('/exam/updateBookExamDate', data, 'post', false, false).then((success) => {
        if (success.status === 1) {
          this.popToast('success', success.message);
          this.BsModalRef.hide();
          this.broadcaster.broadcast('detailsUpdated', 'CsvUserlist');
        } else {
          this.popToast('error', success.message);
        }
      });
    }
  }
}
