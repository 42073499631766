import { Component, Injector, Input, OnInit } from '@angular/core';
import * as grapesjs from 'grapesjs';
import 'grapesjs-preset-newsletter';
import 'grapesjs-preset-webpage';
import { BaseComponent } from '../../common/commonComponent';

@Component({
  selector: 'app-grapes-js',
  templateUrl: './grapes-js.component.html',
})

export class GrapesJSComponent extends BaseComponent implements OnInit {
  public _editor: any;
  public emailTemplateId: any;
  public blocks: any;
  public fieldList = ['fullname', 'lastname', 'role'];
  public showRichTextEditor: boolean = true;
  public panelManager: any;

  // tslint:disable-next-line:no-input-rename
  @Input('emailTemplate') emailTemplate: any;
  // tslint:disable-next-line:no-input-rename
  @Input('type') type: any;
  @Input('typeValue') typeValue
  public plugin: any;

  constructor(public inj: Injector) {
    super(inj);
  }

  // tslint:disable-next-line: cognitive-complexity
  ngOnInit() {
    setTimeout(() => {
      if (this.emailTemplate && this.type) {
        if (this.isBrowser()) {
          this._editor = this.initializeEditor();
        }
        if (this._editor.RichTextEditor.get('insert')) { this._editor.RichTextEditor.remove('insert'); }
        if (this.showRichTextEditor) {
          /*************************************************************/
          // Add Custom Richtexteditor
          /*************************************************************/
          let getNgSelect = document.getElementById('dynamic');
          if (this._editor.RichTextEditor.get('insert')) { this._editor.RichTextEditor.remove('insert'); }
          this._editor.RichTextEditor.add('insert', {
            icon: getNgSelect,
            result: (rte, action) => {
              let data = rte.el.firstChild.ownerDocument;
              if (this.insertValue) {
                data.execCommand('insertText', false, '{{' + this.insertValue + '}}');
                this.insertValue = '';
              }
            },
          });
          /*************************************************************/
        }
        this.panelManager = this._editor.Panels;
        this.panelManager.addButton('options', {
          id: 'undo',
          className: 'fa fa-undo',
          command: (e) => e.runCommand('core:undo'),
          attributes: { title: 'Some title' },
          active: true,
        });
        this.panelManager.addButton('options', {
          id: 'delete',
          className: 'fa fa-trash',
          command: (e) => {
            let res = window.confirm('Are you sure want to clear the canvas?');
            if (res) {
              e.runCommand('core:canvas-clear');
            }
          },
          attributes: { title: 'Delete' },
          active: true,
        });
      }
    }, 1000);
  }
  // tslint:disable-next-line: use-life-cycle-interface
  ngDoCheck() {
    if (this._editor) {
      this.setToken('gjs-html1', this._editor.runCommand('gjs-get-inlined-html'));
    }
  }

  /*************************************************************/
  // Initialize Grapesjs
  /*************************************************************/
  private initializeEditor() {
    if (this.isBrowser()) { }
    return grapesjs.init({
      container: '#gjs',
      fromElement: false,
      autorender: true,
      forceClass: false,
      components: this.emailTemplate.gjsHtml ? this.emailTemplate.gjsHtml : '',
      style: this.emailTemplate.gjsCss ? this.emailTemplate.gjsCss : '',
      plugins: ['grapesjs-preset-newsletter'],
      pluginsOpts: {
        'grapesjs-preset-newsletter': {
          inlineCss: true,
        },
      },
      colorPicker: { appendTo: 'parent', offset: { top: 26, left: -155 } },
      assetManager: {
        assest: [],
        uploadText: 'Add image through link or upload image',
        modalTitle: 'Select Image',
        inputPlaceholder: 'http://url/to/the/image.jpg',
        addBtnText: 'Add image',
        uploadFile: (e) => {
          const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
          let fileUpload = new FormData();
          fileUpload.append('file', file);
          this.commonService.callApi(this.callAPIConstants.AdminFileUpload, fileUpload, 'post', false, true).then((success: any) => {
            if (success.status === 1) {
              this._editor.AssetManager.add(success.data.filePath);
            }
          });
        },
      },
      storageManager: {
        autosave: true,
        setStepsBeforeSave: 1,
        autoload: true,
        type: 'local',
        urlStore: '',
        urlLoad: '',
        contentTypeJson: true,
      },
      handleAdd: (textFromInput) => {
        this._editor.AssetManager.add(textFromInput);
      },
      canvas: {
        styles: [
          'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css',
          'https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css',
        ],
        scripts: ['https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js'],
      },
    });
  }
  /*************************************************************/
  // get value to insert in editor
  /*************************************************************/
  public insertValue: any;
  showValue(event) {
    this.insertValue = event;
  }
  // tslint:disable-next-line: use-life-cycle-interface
  ngOnDestroy() {
    // destroy instance of editor
    this._editor.destroy();
  }
}
