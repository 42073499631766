import { Component, OnInit, Injector } from '@angular/core';
import { BaseComponent } from '../../common/commonComponent';
import { DetailsComponent } from '../details/details.component';

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styles: []
})
export class OfferDetailsComponent extends BaseComponent implements OnInit {
  public centre: any = {};
  public data;
  public user;
  public url;
  public detailsValue;
  public examName;
  public detail: any = {};
  constructor(inj: Injector) { super(inj) }

  /* Mian Banner **/
  BannerOptions = {
    loop: false,
    items: 2,
    margin: 0,
    dots: false,
    autoplayHoverPause: true,
    slidesToScroll: 1,
    autoplay: false,
    nav: true,
    navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>']
    /*  responsive: {
         0: {
           items: 1,
         },
         400: {
           items: 1,
         },
         740: {
           items: 1,
         },
         940: {
           items: 1,
         },
       },**/

  };
  ngOnInit() {
    this.detail.status = 'No';
    this.user.examId = this.data.examDetail[0].examId;
  }
  onSubmit() {
    this.BsModalRef.hide();
    if (this.detail.status == 'Yes' || this.detail.status == 'No') {
      let data: any = {};
      // "emailId": "pallari22@grr.la",
      // "authCentre": "5e2962eae468c87855d5a2eb",
      // "examId" : "5f34cf0453fa254e89b6058d",
      // "qualification" : "5e2eb951f9b4a2e2dee3439c"
      data.authCentre = this.data._id;
      data.qualificationScheduleDate = this.user.qualificationScheduleDate;
      data.examId = this.user.examId;
      data.status = this.detail.status;
      this.setToken('offerPageData', JSON.stringify(data))
      // this.router.navigate(['public/landing-page'], {
      //   queryParams: {
      //     firstName: this.router.parseUrl(this.router.url).queryParams['firstName'],
      //     lastName: this.router.parseUrl(this.router.url).queryParams['lastName'],
      //     email: this.router.parseUrl(this.router.url).queryParams['email']
      //     , mobile: this.router.parseUrl(this.router.url).queryParams['mobile'],
      //     qualificationId: this.router.parseUrl(this.router.url).queryParams['qualificationId'],
      //     city: this.router.parseUrl(this.router.url).queryParams['city'] ? this.toTitleCase(this.router.parseUrl(this.router.url).queryParams['city']) : 'Nellore',
      //   }
      // });
      this.router.navigate(['public/landing-page'], {
        queryParams: {
          firstName: this.router.parseUrl(this.router.url).queryParams['firstName'],
          lastName: this.router.parseUrl(this.router.url).queryParams['lastName'],
          email: this.router.parseUrl(this.router.url).queryParams['email']
          , mobile: this.router.parseUrl(this.router.url).queryParams['mobile'],
          qualificationId: this.user.qualification,
          city: this.user.city
        }
      });

    }
  }
  checkBoxClick(data) {
    delete data.isSelected;

  }
  getqualificationScheduleDate(data) {
    this.user.qualificationScheduleDate = data.qualificationScheduleDate;

  }
  toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }
}
