import { Component, Injector, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/common/commonComponent';
import { StripeCheckoutLoader, StripeCheckoutHandler } from 'ng-stripe-checkout';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { isThisISOWeek } from 'date-fns';
import { data } from 'jquery';
declare var Stripe;
@Component({
  selector: 'app-exam-details',
  templateUrl: './exam-details.component.html',
  styles: [],
})
export class ExamDetailsComponent extends BaseComponent implements OnInit {
  private stripeCheckoutHandler: StripeCheckoutHandler;
  public bookingId: any = {};
  public examDetails: any[] = [];
  @Input() configs: any = {};
  public booked: any;
  public methodName: any = {};
  public tableConfig: any;
  fields: any[] = [];
  type: string;
  public submitted: any;
  public qualificationCentreId: any;
  token: any;
  qualificationAddedBy: any;
  cityArr: any = [];
  card: any;
  cardNumberElement: any;
  clientSecret: any;
  public stripe;
  public elements;
  public user: any = {};
  authCenterArr: any;
  qualificationDatesArr: any;
  update: boolean;
  change: boolean;
  constructor(inj: Injector,private stripeCheckoutLoader: StripeCheckoutLoader
  ) {
    super(inj);

    this.activatedRoute.params.subscribe((params) => {

      this.bookingId = params.id;
      this.qualificationCentreId = params.id1;
      if (params.id2 == 'payment') {
        this.booked = params.id2;
        // this.configs = [...this.configs]
      }
      else {
        this.qualificationAddedBy = params.id2;
      }
    });
  }
  public ngAfterViewInit() {
    if (this.configs.type === 'payExam' && this.loginData.centreRole === 'Authorised_Centre' && this.examDetails[0] && this.examDetails[0].bankDetails &&
      this.examDetails[0].bankDetails.publishableKey) {
      this.stripeCheckoutLoader.createHandler({
        key: this.loginData.bankDetails.publishableKey,
        // image: 'assets/images/CAE.svg',
        // locale: 'auto',
        token: (token) => {
          // Do something with the token...
          this.token = token;
          let data: any = {};
          data.studentId = this.loginData._id;
          data.scheduledQualificationId = this.examDetails[0]._id;
          data.paymentMode = this.examDetails[0].paymentMode;
          data.token = token.id;
          this.examPayment(data)
        },
      }).then((handler: StripeCheckoutHandler) => {
        this.stripeCheckoutHandler = handler;
      });
    }

  }
  ngOnInit() {
    this.tableConfig = this.configs.examData;
    this.callApiToGetData();
    this.getAllQualifications();
    // this.getAcBasedOnCity();

  }
  getAllQualifications() {
    this.commonService.callApi('/admin/getAllQualification', {}, 'post').then((success) => {
      if (success.status === 1) {
        // success.data.forEach(element => {
        //   if (element._id ==this.examDetails[0] && this.examDetails[0].qualificationId) {
        //     this.user.categoryId = element.categoryId
        //   }
        // });
      }
    });
  }
  getAcBasedOnCity() {

    let authData: any = {};
    if(this.examDetails[0] && this.examDetails[0].qualificationId){
      authData.qualification = this.examDetails[0].qualificationId;
    }
    authData.city = this.loginData.city;
    this.commonService.callApi('/students/getAcBasedOnCity', authData, 'post').then((success) => {
      if (success.status === 1) {
        this.authCenterArr = success.data;
      }
    });
  }
  getQualificationDate(qualification, change?) {
    let data: any = {};
    if (change) {
      delete this.user.qualificationScheduleDate;
      data.authcentreId = this.user.center;
      if (this.user.center) {
        this.getQualificationPrices(data);
      }
      // this.getAcDetails();
    }
    else {
      data.authcentreId = this.getToken('acId') ? this.getToken('acId') : this.qualificationCentreId;
    }
    data.qualification = this.examDetails[0].qualificationId;
    data.city = this.loginData.city;
    this.commonService.callApi('/students/getExamDatesBasedOnQualification', data, 'post').then(res => {
      if (res.status === 1) {
        this.qualificationDatesArr = res.data;
      }
    })
  }
  getQualificationPrices(id) {
    let data: any = {};
    // data.categoryId = this.user.categoryId;
    data._id = this.user.center;
    data.city = this.loginData.city;

    this.commonService.callApi('/students/getQualificationList',
      data
      , 'post').then((success) => {
        if (success.status === 1 && success.data[0]) {
          success.data[0].qualification.forEach(e => {
            if (e._id == this.loginData.qualification) {

              this.user.price = e.price;
              this.examDetails[0].price = e.price;
              this.user.qualificationPriceId = e.qualificationPriceId
            }
          })
        }
      });
  }
  changequalificationScheduleDate(data?, change?) {
    if (change) {
      this.user.latestId = data._id;
      this.change = true;

    }

    if (this.update) {
      // this.examDetails[0].qualificationScheduleDate = this.user.qualificationScheduleDate;
    }
  }
  getAcDetails() {
    let data: any = {};
    data.city = this.loginData.city;
    data.qualification = this.loginData.qualification;
    this.commonService.callApi('/students/getAcDetailBasedOnCity', data, 'post').then(res => {
      if (res.status === 1) {
        res.data.forEach((element, index) => {
          if (element._id == this.user.center && this.update) {
            this.examDetails[0].authorisationCentreName = res.data[index].centreName;
            this.examDetails[0].authorisationEmailId = res.data[index].emailId;
            this.examDetails[0].authorisationAddress = res.data[index].address;
            if (res.data[index].city.length && typeof (res.data[index].city) === 'object') {
              this.examDetails[0].city = res.data[index].city.join(',').replace(/,/g, ', ').split();
            }
            if (res.data[index].state.length && typeof (res.data[index].state) === 'object') {
              this.examDetails[0].state = res.data[index].state.join(',').replace(/,/g, ', ').split();
            }
            this.examDetails[0].authorisationCountry = res.data[index].country;
            this.examDetails[0].authorisationContactPersonName = res.data[index].centreContactPersonName;
            this.examDetails[0].authorisationMobile = res.data[index].mobile;
            this.examDetails[0].alternateContactNumber = res.data[index].alternateContactNumber;
            this.examDetails[0].authorisationPaymentType = res.data[index].acceptPaymentType;
            if (res.data[index].bankDetails && res.data[index].bankDetails.publishableKey) {
              this.examDetails[0].bankDetails = res.data[index].bankDetails;
            }

          }
        });
      }
    })
  }
  submitPayment(examDetails) {

    this.stripe.confirmCardPayment(this.clientSecret, {
      payment_method: {
        card: this.cardNumberElement,
        billing_details: {
          name: this.loginData.centreName
        }
      }
    }).then((result) => {
      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        Swal.fire({
          position: 'center',
          html: '<div class="sweetalert-outer invalid-file-outer"><p>' + `${result.error.message}` + '</p><img src="assets/images/upload-successfully.png"/></div>',
          confirmButtonText: 'Try Again',
          // tslint:disable-next-line: no-duplicate-string
          confirmButtonClass: 'btn btn-secondary',
        });
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
          setTimeout(() => {
            let data: any = {};
            data.scheduledQualificationId = examDetails[0]._id;
            data.paymentMode = examDetails[0].paymentMode;
            data.paymentIntent = result.paymentIntent;
            this.examPayment(data);
          }, 100);


          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
        }
      }
    });
  }

  public onClickBuy() {

  }
  public onClickCancel() {
    // If the window has been opened, this is how you can close it:
    this.stripeCheckoutHandler.close();
  }
  public data: any = {};

  /*************************************************************
 @purpose : TO Get Data.
  /*************************************************************/
  callApiToGetData() {
    if (this.loginData.centreRole === 'Authorised_Centre') {
      this.data._id = this.getToken('scheduleExamId') ? this.getToken('scheduleExamId') : this.bookingId;
      this.data.authId = this.getToken('acId') ? this.getToken('acId') : this.qualificationCentreId;
    }
    else {
      this.data._id = this.bookingId;
      this.data.prepId = this.qualificationCentreId;
      this.data.authId = this.qualificationAddedBy;
    };


    let method: any;
    if (this.configs.type === 'bookExam') {
      method = 'post';
      this.methodName = this.callAPIConstants.BookExam;
    } else if (this.configs.type === 'payExam') {
      method = 'post';
      this.methodName = this.callAPIConstants.BookExam;
    } else if (this.configs.type === 'upcomingExam') {
      method = 'get';
      this.data = {};
      this.methodName = '/students/upcomingExamDetails';
    } else if (this.configs.type === 'View Exam') {
      method = 'get';
      this.methodName = '/students/upcomingExamDetails';
      this.data = {};
      this.fields = [{ name: 'Country' }, { name: 'Qualification' }];
      // tslint:disable-next-line: no-unused-expression
      this.type = 'upComingExam';
    }


    this.commonService.callApi(this.methodName, this.data, method).then((success) => {
      if (success.status === 1 && success.data.listing[0]) {
        this.examDetails = [];
        this.examDetails.push(success.data.listing[0]);
        if (typeof (this.examDetails[0].authorisationCity) === 'string') {
          this.examDetails[0].city1 = this.examDetails[0].authorisationCity;
          this.user.qualificationScheduleDate = this.getToken('date') ? this.getToken('date') : this.examDetails[0].qualificationScheduleDate;
          this.user.latestId = this.examDetails[0]._id;
          if (this.examDetails[0].bankDetails && this.examDetails[0].bankDetails.publishableKey) {
            this.stripe = Stripe(this.examDetails[0].bankDetails.publishableKey);
            this.elements = this.stripe.elements();
          }
          if (this.configs.type == 'payExam') {
            this.getAcBasedOnCity();
            this.user.center = this.getToken('acId') ? this.getToken('acId') : this.qualificationCentreId;
            this.getQualificationDate(this.examDetails[0].qualificationId);

          }
        }
        if (typeof (this.examDetails[0].authorisationCity) === 'object') {
          this.examDetails[0].city1 = [];
          this.examDetails[0].state = [];
          this.examDetails[0].city1 = this.examDetails[0].authorisationCity.join(',').replace(/,/g, ', ').split();
          this.user.latestId = this.examDetails[0]._id;
          this.user.qualificationScheduleDate = this.getToken('date') ? this.getToken('date') : this.examDetails[0].qualificationScheduleDate;
          if (this.examDetails[0].bankDetails && this.examDetails[0].bankDetails.publishableKey) {
            this.stripe = Stripe(this.examDetails[0].bankDetails.publishableKey);
            this.elements = this.stripe.elements();
          }
          if (this.configs.type == 'payExam') {
            this.getAcBasedOnCity();
            this.user.center = this.getToken('acId') ? this.getToken('acId') : this.qualificationCentreId;
            this.getQualificationDate(this.examDetails[0].qualificationId);

          }
        }
        if (typeof (this.examDetails[0].authorisationState) === 'string') {
          this.examDetails[0].state = this.examDetails[0].authorisationState;
          this.user.qualificationScheduleDate = this.getToken('date') ? this.getToken('date') : this.examDetails[0].qualificationScheduleDate;
          this.user.latestId = this.examDetails[0]._id;
          if (this.examDetails[0].bankDetails && this.examDetails[0].bankDetails.publishableKey) {
            this.stripe = Stripe(this.examDetails[0].bankDetails.publishableKey);
            this.elements = this.stripe.elements();
          }
          if (this.configs.type == 'payExam') {
            this.getAcBasedOnCity();
            this.user.center = this.getToken('acId') ? this.getToken('acId') : this.qualificationCentreId;
            this.getQualificationDate(this.examDetails[0].qualificationId);

          }
        }
        if (typeof (this.examDetails[0].authorisationState) === 'object') {
          this.examDetails[0].city = [];
          this.examDetails[0].state = [];
          this.examDetails[0].state = this.examDetails[0].authorisationState.join(',').replace(/,/g, ', ').split();
          this.user.latestId = this.examDetails[0]._id;
          this.user.qualificationScheduleDate = this.getToken('date') ? this.getToken('date') : this.examDetails[0].qualificationScheduleDate;
          if (this.examDetails[0].bankDetails && this.examDetails[0].bankDetails.publishableKey) {
            this.stripe = Stripe(this.examDetails[0].bankDetails.publishableKey);
            this.elements = this.stripe.elements();
          }
          if (this.configs.type == 'payExam') {
            this.getAcBasedOnCity();
            this.user.center = this.getToken('acId') ? this.getToken('acId') : this.qualificationCentreId;
            this.getQualificationDate(this.examDetails[0].qualificationId);

          }
        }
        if (this.configs.type == 'upcomingExam' || this.configs.type == 'View Exam' && this.examDetails[0].bookedForDate && typeof (this.examDetails[0].city) === 'string') {
          this.examDetails[0].upComingcity = this.examDetails[0].city;
          this.examDetails[0].upComingstate = this.examDetails[0].state;
          this.user.qualificationScheduleDate = this.examDetails[0].bookedForDate;
        }
        if (this.configs.type == 'upcomingExam' || this.configs.type == 'View Exam' && this.examDetails[0].bookedForDate && typeof (this.examDetails[0].city) === 'object') {
          this.examDetails[0].upComingcity = [];
          this.examDetails[0].upComingstate = [];
          this.examDetails[0].upComingcity = this.examDetails[0].city.join(',').replace(/,/g, ', ').split();
          this.examDetails[0].upComingstate = this.examDetails[0].state.join(',').replace(/,/g, ', ').split();
          this.user.qualificationScheduleDate = this.examDetails[0].bookedForDate;

        }
        this.examDetails.forEach((e) => {
          if (this.type !== 'upcominExam') {

            // tslint:disable-next-line: no-unused-expression
            if (this.loginData.centreRole) {
              if(e.authorisationPaymentType && e.authorisationPaymentType.length === 2){
                e.authorisationPaymentType = ['Offline', ' Online']
              }
              // (e.authorisationPaymentType && e.authorisationPaymentType.length === 2) ? e.authorisationPaymentType = ['Offline', ' Online'] : e.authorisationPaymentType;
            }
          }
          if (e.qualificationAmount) {
            e.qualificationAmount = e.qualificationAmount + ' ' + 'INR';
          }
          if (e.price && this.configs.type == 'bookExam') {
            e.price = e.price + ' ' + 'INR';
          }

          if (this.configs.type === 'upcomingExam' || this.configs.type === 'View Exam') {
            if (e.bookedAmount) {
              e.bookedAmount = e.bookedAmount + ' ' + 'INR';

            }
          }
        });
        // if (this.configs.type === 'View Exam') {
        //   this.examDetails[0].bookedPaymentMode = this.getToken('paymentMode');
        // }
      }
    });
  }

  /*************************************************************
 @purpose : To Go back.
  /*************************************************************/
  goBack() {
    this.location.back();
  }
  /*************************************************************
@purpose : To Book Exam and complete the payment.
/*************************************************************/
  bookExam(buttonTitle, id, templateRef?) {
    if (buttonTitle === 'BOOK YOUR EXAM') {
      this.router.navigate([this.URLConstants.EXAMSUMMARY, id._id, id.authorisationId, id.qualificationAddedBy]);
    } else if (buttonTitle === 'PAY') {
      this.submitted = true;
      let data: any = {};
      data.scheduledQualificationId = this.examDetails[0]._id;
      data.paymentMode = this.examDetails[0].paymentMode;
      if(data.paymentMode ==='Offline' || data.paymentMode === 'offline'){
        data.paymentMode = 'Offline'; } else{  data.paymentMode = 'Online'; }
      if (data.paymentMode) {
        if (data.paymentMode == 'Online') {
          // this.stripeCheckoutHandler.open({
          //   name: 'Cambridge Assessement English',
          //   // image: 'assets/images/CAE.svg',
          //   amount: id.price * 100,
          //   currency: 'INR',
          //   // email:
          // });

          this.BsModalRef = this.modalService.show(templateRef, { class: 'modal-md modal-dialog-centered' });
          var style = {
            base: {
              iconColor: '#666EE8',
              color: '#31325F',
              lineHeight: '40px',
              fontWeight: 300,
              fontFamily: 'Helvetica Neue',
              fontSize: '15px',

              '::placeholder': {
                color: '#CFD7E0',
              },
            },
          };
          this.elements = this.stripe.elements();
          this.cardNumberElement = this.elements.create('cardNumber', {
            style: style
          });
          this.cardNumberElement.mount('#card-number-element');

          var cardExpiryElement = this.elements.create('cardExpiry', {
            style: style
          });
          cardExpiryElement.mount('#card-expiry-element');

          var cardCvcElement = this.elements.create('cardCvc', {
            style: style
          });
          cardCvcElement.mount('#card-cvc-element');
          this.commonService.callApi('/students/examPaymentInitiate', { scheduledQualificationId: this.examDetails[0]._id, price: this.examDetails[0].price }, 'post').then(response => {
            if (response.status === 1) {
              this.clientSecret = response.data;
            }
          })
        }
        if (this.examDetails[0].paymentMode == 'Offline') {
          data.authCentreId = this.user.center;
          data.scheduledExamDate = this.user.qualificationScheduleDate;
          data.bookExamId = this.examDetails[0]._id;
          this.examPayment(data);

        }
      // }
    } 
  }else if (buttonTitle === 'View Details') {
    this.router.navigate([this.URLConstants.EXAMDETAIL, id.scheduledQualificationId, id.authcentreId]);
  }
}
  examPayment(data) {
    data['price'] = this.examDetails[0].price;
    data.studentId = this.loginData._id;
    // data['price'] = 9876;
    this.commonService.callApi('/students/examPayment', data, 'post').then((success) => {
      if (success.status === 1) {
        if (this.BsModalRef) {
          this.BsModalRef.hide();
        }
        Swal.fire({
          position: 'center',
          html: '<div class="sweetalert-outer invalid-file-outer"><h4>Thank You For Registering</h4><p>' + `${success.message}` + '</p><img src="assets/images/upload-successfully.png"/></div>',
          confirmButtonText: 'Done',
          confirmButtonClass: 'btn btn-secondary',
        });
        this.router.navigate([this.URLConstants.UPCOMINGEXAM, '']);
      } else {
        Swal.fire({
          position: 'center',
          html: '<div class="sweetalert-outer invalid-file-outer"><h4>No Schedule Exams</h4><p>' + `${success.message}` + '</p><img src="assets/images/upload-successfully.png"/></div>',
          confirmButtonText: 'Try Again',
          // tslint:disable-next-line: no-duplicate-string
          confirmButtonClass: 'btn btn-secondary',
        });
      }
    });
  }
  updateAcAndCity() {
    this.swal({
      text: `Do you want to update the AC and Qualification Date? `,
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#DB3C36',
      cancelButtonColor: '#DB3C36',
      confirmButtonClass: 'btn btn-primary',
      cancelButtonClass: 'btn btn-primary',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.value) {
        let data: any = {};
        data.studentId = this.loginData._id;
        data.authCentreId = this.user.center;
        data.qualification = this.examDetails[0].qualificationId;
        data.examId = this.examDetails[0]._id;
        // if (this.change) {
        data.scheduleExamId = this.user.latestId;
        // }
        this.commonService.callApi('/students/editDateAndCentreBeforePay', data, 'post').then(res => {
          if (res.status == 1) {
            this.popToast('success', res.message);
            this.setToken('acId', this.user.center);
            this.setToken('date', this.user.qualificationScheduleDate);
            this.setToken('scheduleExamId', this.user.latestId);
            this.callApiToGetData();

            this.update = true;
            // this.getAcDetails();
            this.changequalificationScheduleDate();

          }
          else {
            this.popToast('error', res.message);
          }
        })
      }
      else {
      }
    });


  }
}
