import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../../common/commonComponent';

declare var jquery: any;
declare var $: any;
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
})

export class HeaderComponent extends BaseComponent implements OnInit {
	isLogin: boolean;
	constructor(inj: Injector) {
		super(inj);
	}
	isCollapsed = true;
	fragment = '';
	ngOnInit() {
		// this.sidepanelOnOff()
		// tslint:disable-next-line: only-arrow-functions
		$('[data-toggle="offcanvas"]').on('click', function () {
			$('.sidebar-offcanvas').toggleClass('active');
		});
		// tslint:disable-next-line: no-duplicate-string
		$('body').addClass('asideleft-maximize');
		$(".top-header .navbar-dark .navbar-nav .nav-item  a[href^='#']").click(function (e) {
			e.preventDefault();

			$(this).parents('.navbar-nav').find('.active').removeClass('active');

			var position = $($(this).attr("href")).offset().top;

			$(this).addClass('active');

			// $("body, html").animate({
			// 	scrollTop: position
			// } /* speed */);

		});
		this.activatedRoute.params.subscribe((param: any) => {
			this.fragment = param.id;
		})
		// if (this.fragment === 'hero_banner' || this.fragment === 'features' || this.fragment === 'advantage' ||this.fragment =='about' ||this.fragment =='contact') {
		// $(window).scroll(function () {
		// 	var scrollDistance = $(window).scrollTop();

		// 	$('section').each(function (i) {
		// 		if ($(this).position().top - 90 <= scrollDistance) {
		// 			$('.nav-item a.active').removeClass('active');
		// 			$('.nav-item a').eq(i).addClass('active');
		// 		}
		// 	});
		// }).scroll()
		// }
		// else {
		// 	$(window).scroll(function () {
		// 		var scrollDistance = $(window).scrollTop();

		// 		$('section').each(function (i) {
		// 			if ($(this).position().top <= scrollDistance) {
		// 				$('.nav-item a.active').removeClass('active');
		// 				$('.nav-item a').eq(i).addClass('active');
		// 			}
		// 		});
		// 	}).scroll()
		// }

	}


	ngDoCheck() {
		if (this.platformId) {
			if (this.getToken("accessToken")) {
				this.isLogin = true;
			} else {
				this.isLogin = false;
			}
		}
	}

	sidepanel() {
		$('body').toggleClass('asideleft-minimize');
		$('body').toggleClass('asideleft-maximize');
	}
	sidepanelOnOff() {
		$('body').toggleClass('asideleft-minimize');
		$('body').toggleClass('asideleft-maximize');
	}
	asideLeftShow() {
		$('body').toggleClass('asideleft-on');
	}
	BannerOptions = {
		loop: true,
		items: 1,
		margin: 0,
		nav: false,
		dots: true,
		autoplay: true,
	};
	logoClicked() {
		this.fragment = 'hero_banner';
		this.router.navigate(['']);
		window.scrollTo(0, 0);
	}
}
