import { Injectable, Injector } from '@angular/core';
import { CanActivate } from '@angular/router';
// Component
import { BaseComponent } from './commonComponent';

/****************************************************************************
@PURPOSE      : Don't allow public pages to get accessed. (After Login)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
@Injectable()
export class CanLoginActivate extends BaseComponent implements CanActivate {
  constructor(inj: Injector) { super(inj); }
  canActivate() {
    if (!this.getToken('accessToken')) {
      return true;
    }
    if (this.loginData) {
      // tslint:disable-next-line: no-duplicate-string
      this.loginData.role === 'Student/Parents' ?
        this.router.navigate([this.URLConstants.CENTRESLIST]) :
        this.router.navigate([this.URLConstants.COMMMON_DASHBOARD]);
    }
    return false;
  }
}
/****************************************************************************/

/****************************************************************************
@PURPOSE      : Don't allow authorized pages to get accessed.  (Before Login)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
// tslint:disable-next-line: max-classes-per-file
@Injectable()
export class CanPrepAuthActivate extends BaseComponent implements CanActivate {
  constructor(inj: Injector) { super(inj); }
  canActivate() {
    if (this.getToken('accessToken') && (this.loginData.role === 'Authorised_Centre' || this.loginData.role === 'Preparation_Centre')) {
      return true;
    }
    this.router.navigate([this.URLConstants.LOGIN]);
    return false;
  }
}
/****************************************************************************/
/****************************************************************************
@PURPOSE      : Don't allow authorized pages to get accessed.  (Before Login)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
// tslint:disable-next-line: max-classes-per-file
@Injectable()
export class CanAuthActivate extends BaseComponent implements CanActivate {
  constructor(inj: Injector) { super(inj); }
  // tslint:disable-next-line: no-identical-functions
  canActivate() {
    if (this.getToken('accessToken') && (this.loginData.role === 'Authorised_Centre' && this.loginData.role !== 'Preparation_Centre')) {
      return true;
    }
    if (this.loginData) {
      if (this.loginData.role === 'Preparation_Centre') {
        this.router.navigate([this.URLConstants.COMMMON_DASHBOARD]);
      } else if (this.loginData.role === 'Student/Parents') {
        this.router.navigate([this.URLConstants.CENTRESLIST]);
      }
    }
    return false;
  }
}
/****************************************************************************/

/****************************************************************************
@PURPOSE      : Don't allow authorized pages to get accessed.  (Before Login)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
// tslint:disable-next-line: max-classes-per-file
@Injectable()
export class CanStudentParentActivate extends BaseComponent implements CanActivate {
  constructor(inj: Injector) { super(inj); }
  // tslint:disable-next-line: no-identical-functions
  canActivate() {
    if (this.getToken('accessToken') && this.loginData.role === 'Student/Parents') {
      return true;
    }
    if (this.loginData) {
      if (this.loginData.role === 'Preparation_Centre') {
        this.router.navigate([this.URLConstants.COMMMON_DASHBOARD]);
      } else if (this.loginData.role === 'Authorised_Centre') {
        this.router.navigate([this.URLConstants.COMMMON_DASHBOARD]);
      }
    }
    this.router.navigate([this.URLConstants.LOGIN]);
    return false;
  }
}
/****************************************************************************/

/****************************************************************************
@PURPOSE      : Don't allow authorized pages to get accessed.  (Before Login)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
// tslint:disable-next-line: max-classes-per-file
@Injectable()
export class CanProfileActivate extends BaseComponent implements CanActivate {
  constructor(inj: Injector) { super(inj); }
  // tslint:disable-next-line: no-identical-functions
  canActivate() {
    if (this.getToken('accessToken')) {
      return true;
    }
    this.router.navigate([this.URLConstants.LOGIN]);
    return false;
  }
}
/****************************************************************************/

/****************************************************************************
@PURPOSE      : Don't allow authorized pages to get accessed.  (Before Login)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
// tslint:disable-next-line: max-classes-per-file
// @Injectable()
// export class CanIsProfileUpdateActivate extends BaseComponent implements CanActivate {
//   constructor(inj: Injector) { super(inj); }
//   // tslint:disable-next-line: no-identical-functions
//   canActivate() {
//     if (this.getToken('accessToken')) {
//       return true;
//     }
//     if (this.loginData.isProfileUpdated === 'false') {
//        return false;
//     }
//     this.router.navigate([this.URLConstants.LOGIN]);
//     return false;
//   }
// }
/****************************************************************************/