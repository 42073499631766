export const URLConstants = {

    LOGIN: '/public/login',
    REGISTER: '/public/register',
    OTP: '/public/otp-verification',
    FORGOT_PASS: '/public/forget-password',
    RESET_PASS: '/public/reset-password',
    MY_PROFILE: '/my-profile',
    SET_PASS: '/public/set-password',
    CHANGE_PASS: '/main/change-password',
    DASHBOARD: '/dashboard',
    CONTACTAC: '/main/contactAc',
    STUDENTCALENDAR: '/studentCalendar',
    STUDENTCALENDAR2020: '/studentCalendar2020',
    COMMMON_DASHBOARD: '/main/dashboard',
    UPLOAD_CSV: '/main/uploadCSV',
    MY_CSV: '/main/uploadCSV/mycsv',
    STUDENT_LIST: '/main/uploadCSV/mycsv/studentList',
    REGISTERED_CANDIDATES: '/main/uploadCSV/mycsv/registeredCandidates',
    PREPARATION_CENTRE: '/main/preparation-centre',
    PREPARATION_CENTRE_detail: '/main/preparation-centre/viewPc',
    ADD_EDIT_PC: '/main/preparation-centre/viewPc',
    VIEW_PC: '/main/preparation-centre/detail',
    COMMUNICATION: '/main/communication',
    SENDING_NOTIFICATIONS: '/main/communication/notification',
    TEMPLATES: '/main/communication/templates',
    ADD_EDIT_TEMPLATE: '/main/communication/detail',
    CAMPAIGN_MANAGER: '/main/campaign-manager',
    CAMPAIGN_ANALYTICS: '/main/campaign-manager/campaign-analytics',
    Leads: '/main/communication/leads',
    MyLeads: '/main/communication/myLeads',
    SELECTLEADFILE: '/main/communication/select-lead-file',
    MyLeadsStudents: '/main/communication/myLeadsStudents',
    MY_EXAMS: '/main/my-exams',
    ADD_EDIT_EXAM: '/main/my-exams/details',
    DAYVIEW: '/main/my-exams/day-view',
    DAYVIEW_LIST: '/main/my-exams/day-view-list',
    MYCALENDAR: '/main/my-exams/my-calendar',
    MYPREVCALENDAR: '/main/my-exams/my-previous-calendar',
    ENROLMENT: '/main/my-exams/enrolment',
    WALKIN: '/main/my-exams/walk-in',
    TERMS: '/public/terms-conditions',
    PRIVACY: '/public/privacy-policy',
    DATA: '/public/data-protection',
    PRIVACYPROTECTION: '/public/dataProtection&privacyPolicy',
    CENTRESLIST: '/student-parent',
    BOOKEXAM: '/student-parent/book-exam',
    EXAMSUMMARY: '/student-parent/exam-summary',
    UPCOMINGEXAM: '/student-parent/upcoming-exam',
    MY_BOOKED_EXAMS: '/student-parent/booked-exams',
    EXAMDETAIL: '/student-parent/exam-detail',
    EXAMPRICING: '/main/exam-pricing',
    AddQUALIFIACATION: '/main/exam-pricing/detail',
    EDIT_QUALIFICATION: '/main/exam-pricing/detail/',
    REGISTER_CANDIDATES: '/main/view-candidates/registered',
    ENROLLMENT: '/main/view-candidates/enrollment',
    WALK_IN: '/main/view-candidates/walkin',
    VIEW_CANDIDATES: '/main/view-candidates',
    DownloadCsv: '/main/uploadCSV/downloadCsv',
    SENDNOTIFICATION: '/main/communication/send-notification'

};
