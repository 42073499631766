import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '../../common/commonComponent';

@Component({
	selector: 'app-profile-photo',
	templateUrl: './profile-photo.component.html',
})
export class ProfilePhotoComponent extends BaseComponent implements OnInit {
	noPhoto: boolean = true;
	subscribed1: any;
	submitted: any;
	@Input() userData: any = {};
	@Output() profilePhotoChange = new EventEmitter();
	@ViewChild('personalPhotoForm') personalPhotoForm: ElementRef;
	prevImage: any;
	noImage: boolean;
	Image: any;
	ImageUpload: any;
	UpdateProfile: string;

	constructor(inj: Injector) {
		super(inj);
		//Form submission event
		this.subscribed1 = this.broadcaster.on<boolean>('submitted').subscribe((success) => {
			this.submitted = success;
			this.profilePhotoChange.emit({ data: this.userData, form: this.personalPhotoForm['valid'] });
		});
	}

	ngOnInit() {
		if (this.userData.photo === '') {
			this.noImage = true;
			this.Image = true;
		} else {
			// this.userData.photo = this.userData.photo;
			this.Image = true;
			this.noImage = false;

		}
		if (this.loginData.role === 'Authorised_Centre') {
			this.ImageUpload = this.callAPIConstants.AuthProfileUpload;
			this.UpdateProfile = this.callAPIConstants.ACProfileUpdate;

		} else if (this.loginData.role === 'Preparation_Centre') {
			this.ImageUpload = this.callAPIConstants.PrepProfileUpload;
			this.UpdateProfile = this.callAPIConstants.PCProfileUpdate;
		} else {
			this.ImageUpload = this.callAPIConstants.StudentProfileUpload;
			this.UpdateProfile = this.callAPIConstants.StudentProfileUpdate;
		}
	}
	/****************************************************************************
			@PURPOSE      : image uploading
	/****************************************************************************/
	public file: any;
	imageChangedEvent: any = '';
	croppedImage: any = '';
	public sliceSize: any;
	public imageURL = '';
	imageCropped(event) {
		this.croppedImage = event.base64;
		const ImageURL = event.base64;
		// Split the base64 string in data and contentType
		const block = ImageURL.split(';');
		// Get the content type of the image
		const contentType = block[0].split(':')[1];
		// In this case 'image/gif'
		// get the real base64 content of the file
		const realData = block[1].split(',')[1];
		// In this case "R0lGODlhPQBEAPeoAJosM...."
		// Convert it to a blob to upload
		this.b64toBlob(realData, contentType, this.sliceSize);
	}

	imageLoaded() {
		// show cropper
	}

	loadImageFailed() {
		// show message
	}
	/****************************************************************************/
	// @PURPOSE      : To Select and to upload the file
	/*****************************************************************************/
	fileChangeEvent(event: any, template): void {
		this.imageChangedEvent = event;
		this.prevImage = event.target.files[0];
		if (event.target.files.length) {
			this.BsModalRef = this.modalService.show(template);
		}
		event.preventDefault();
	}
	b64toBlob(b64Data, contentType, sliceSize) {
		contentType = contentType || '';
		sliceSize = sliceSize || 512;
		let byteCharacters = atob(b64Data);
		let byteArrays = [];
		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			let slice = byteCharacters.slice(offset, offset + sliceSize);
			let byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			let byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}
		let blob = new Blob(byteArrays, { type: contentType });
		this.file = new File([blob], this.prevImage.name, { type: contentType, lastModified: Date.now() });
	}
	private blobToFile(theBlob: Blob, fileName: string): File {

		const b: any = theBlob;
		b.lastModifiedDate = new Date();
		b.name = fileName;
		return b as File;
	}
	submitcropped() {
		this.userData.photo = URL.createObjectURL(this.prevImage);
		this.BsModalRef.hide();
		this.Image = false;
	}
	/****************************************************************************/
	// @PURPOSE      : To open list of files
	/*****************************************************************************/
	openfile(event: any) {
		event.preventDefault();
		const element = document.getElementById('profile');
		element.click();
	}

	/****************************************************************************/

	/****************************************************************************/
	// @PURPOSE      : To Remove the photo updated
	/*****************************************************************************/
	removeImage() {
		this.commonService.callApi('/authcentre/centreDeleteImage', { _id: this.loginData._id }, 'post', false, false).then((success) => {
			if (success.status === 1) {
				// tslint:disable-next-line: no-commented-code
				this.popToast('success', 'Profile image removed');
				this.userData.photo = '';
				this.Image = true;
				this.noImage = true;
			} else {
				this.popToast('error', success.message);
			}
		});
	}
	/*****************************************************************************/
	uploadImage() {
		const fd = new FormData();
		this.file ?
			fd.append('file', this.file) :
			fd.append('file', this.userData.photo);
		fd.append('_id', this.loginData._id);
		this.commonService.callApi(this.ImageUpload, fd, 'post', false, true).then((success: any) => {
			if (success.status === 1) {
				this.userData.photo = success.data.filePath;
				this.commonService.callApi(this.UpdateProfile, { photo: this.userData.photo }, 'post').then((success) => {
					if (success.status === 1) {
						this.popToast('success', 'Profile image updated');
						this.userData.photo = success.data[0].photo;
						this.setToken('data', JSON.stringify(success.data[0]));
						this.noImage = false;
						this.Image = true;
					} else {
						this.popToast('error', success.message);
					}
				});
			} else {
				this.popToast('error', success.message);
			}
		});
	}
	ngOnDestroy() {
		this.subscribed1.unsubscribe();
	}
}
