import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../common/commonComponent';

@Component({
	selector: 'app-my-profile',
	templateUrl: './my-profile.component.html',
})
export class MyProfileComponent extends BaseComponent implements OnInit {
	submitted = false;
	profileForm: boolean;
	changePasswordForm: boolean;
	bankDetailForm: boolean;
	bankData: any = {};
	profileData: any = {};
	passwordData: any = {};
	sample1: any = {};
	sample2: Array<any> = [];
	sample3: any = {};
	sample4: any = {};
	UpdateProfile: string;
	profileHeading: any = String;
	condition: boolean;

	constructor(inj: Injector) {
		super(inj);
	}

	ngOnInit() {
		if (this.loginData.role === 'Authorised_Centre') {
			this.profileHeading = 'Personal Information';
			this.UpdateProfile = this.callAPIConstants.ACProfileUpdate;
			// this.loginData.isProfileUpdated = true;
		} else if (this.loginData.role === 'Preparation_Centre') {
			this.profileHeading = 'Preparation Centre Details';
			this.UpdateProfile = this.callAPIConstants.PCProfileUpdate;
		} else {
			this.profileHeading = 'Personal Information';
			this.UpdateProfile = this.callAPIConstants.StudentProfileUpdate;
		}

		this.sample1 = {
			centreName: this.loginData.centreName,
			mobile: this.loginData.mobile,
			entityType: this.loginData.entityType,
			city: this.loginData.city,
			emailId: this.loginData.emailId,
			country: this.loginData.country ? this.loginData.country : this.loginData.country = 'India',
			address: this.loginData.address,
			state: this.loginData.state,
			centreContactPersonName: this.loginData.centreContactPersonName,
			acceptPaymentType: this.loginData.acceptPaymentType,
			firstName: this.loginData.firstName,
			lastName: this.loginData.lastName,
			prepCentreId: this.loginData.centreName,
		};
		if (!this.loginData.isRegisteredFromLanindg) { }
		if (this.loginData.centreRole == 'Authorised_Centre') {
			this.sample1.authCentre = this.loginData.authCentre;
		}
		else if (this.loginData.centreRole == 'Preparation_Centre') {
			// this.sample1.authCentre = this.loginData.authCentre;
			this.sample1.prepCentreName = this.loginData.authCentre;
		}
		this.sample2 = this.loginData.qualification;
		this.sample3 = this.loginData.bankDetails;
		this.sample4 = this.loginData;
	}

	/****************************************************************************/
	// tslint:disable-next-line: cognitive-complexity
	onUpdateProfile() {
		this.broadcaster.broadcast('submitted', true);
		// tslint:disable-next-line: no-duplicate-string
		this.condition = this.loginData.role === 'Student/Parents' ? (this.profileForm && this.changePasswordForm) : (this.loginData.isProfileUpdated ?
			(this.profileForm && this.changePasswordForm)
			: (this.changePasswordForm));
		if (this.condition && (this.passwordData.newPassword === this.passwordData.confirmPassword)) {
			let tempData = this.loginData.role === 'Student/Parents' ? Object.assign(this.profileData, this.passwordData) : (this.loginData.isProfileUpdated ?
				Object.assign(this.profileData, this.passwordData) :
				Object.assign(this.passwordData));
			if (this.loginData.role = 'Authorised_Centre') {
				tempData.bankDetails = Object.assign(this.bankData);
			}
			this.commonService.callApi(this.UpdateProfile, tempData, 'post').then((success) => {
				if (success.status === 1) {
					this.popToast('success', success.message);
					if (this.loginData.role === 'Student/Parents' || (success.data[0].role == 'Student/Parents') || (this.loginData.upcomingExamDetails && this.loginData.upcomingExamDetails.length)) {
						if (success.data[0].authCentre && success.data[0].authCentre['_id'] && this.loginData.centreRole === 'Authorised_Centre') {
							success.data[0]['centreName'] = success.data[0].authCentre.centreName,
								success.data[0]['authCentreMobile'] = success.data[0].authCentre.mobile;
							success.data[0]['authCentreEmailID'] = success.data[0].authCentre.emailId;
							success.data[0]['authCentreAddress'] = success.data[0].authCentre.address;
							success.data[0]['centreRole'] = 'Authorised_Centre';
							success.data[0]['authCentre'] = success.data[0].authCentre._id;
						}
						if (success.data[0].prepCentreName && success.data[0].prepCentreName._id) {
							success.data[0]['centreName'] = success.data[0].prepCentreName.centreName;
							success.data[0]['authCentreMobile'] = success.data[0].prepCentreName.mobile;
							success.data[0]['authCentreEmailID'] = success.data[0].prepCentreName.emailId;
							success.data[0]['authCentreAddress'] = success.data[0].prepCentreName.address;
							success.data[0]['centreRole'] = 'Preparation_Centre';
							success.data[0]['prepCentreName'] = success.data[0].prepCentreName._id;
						}
					}
					if (this.loginData.role == 'Student/Parents' && this.loginData.isRegisteredFromLanindg) {
						success.data[0]['isRegisteredFromLanindg'] = true;
					}
					this.setToken('data', JSON.stringify(success.data[0]));
					this.router.navigate([this.URLConstants.COMMMON_DASHBOARD]);
					// this.loginData.role === 'Student/Parents' ?
					//  this.router.navigate([this.URLConstants.DASHBOARD]) :
					//  this.router.navigate([this.URLConstants.COMMMON_DASHBOARD]);
				} else {
					this.popToast('error', success.message);
				}
			});
		} else {
			if (this.loginData.isProfileUpdated) {
				if (this.profileForm) {
					if (this.changePasswordForm) {
						if (this.bankDetailForm) {
							this.popToast('error', 'Please fill the form');
						} else {

							if (this.loginData.role == 'Authorised_Centre') {
								this.popToast('error', 'Please fill the Payment details');
							}
							// tslint:disable-next-line: no-duplicate-string
						}
					} else {
						if (this.loginData && this.loginData.isProfileUpdated) {
							this.popToast('error', 'Please fill change password form');
						} else {
							this.popToast('error', 'It is mandatory to change password');
						}
					}
				} else {
					this.popToast('error', 'Please fill personal information form');
				}
			} else {
				if (this.changePasswordForm) {
					// if (!this.bankDetailForm) {
					// 	this.popToast('error', 'Please fill Payment details');
					// }
				} else {
					this.popToast('error', 'It is mandatory to change password');
				}
			}
		}
	}
	/**********************************************************************
	@PURPOSE      :  For Getting Data related to Profile Details component
	/**********************************************************************/
	profileInfoFunc(event) {
		this.profileForm = event.form;
		this.profileData = event.data;

	}
	/***********************************************************************
	@PURPOSE      :  For Getting Data related to changepassword component
	/**********************************************************************/
	passwordChange(event) {
		this.changePasswordForm = event.form;
		this.passwordData = event.data;
	}
	/**********************************************************************
	@PURPOSE      :  For Getting Data related to bankDetails component
	/**********************************************************************/
	bankDetailsChange(event) {
		this.bankDetailForm = event.form;
		this.bankData = event.data;
	}
}

