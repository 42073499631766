import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule, MatPaginatorModule, MatSlideToggleModule, MatTooltipModule } from '@angular/material';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
// plugin
import { NgSelectModule } from '@ng-select/ng-select';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DataTablesModule } from 'angular-datatables';
import { BsDatepickerModule, ModalModule, PaginationModule, TabsModule, TooltipModule } from 'ngx-bootstrap';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DragDropDirective } from 'src/app/common/directives/drag-drop.directive';
// tslint:disable-next-line: ordered-imports
import { CentresListComponent } from 'src/app/student-parent/centres-list/centres-list.component';
// common
import { BnDatatableModule } from '../../common/bn-datatable/bn-datatable.module';
import { BaseComponent } from '../../common/commonComponent';
import { GooglePlacesDirective } from '../../common/directives/google-places.directive';
import { GrapesJSComponent } from '../../reusable/grapes-js/grapes-js.component';
import { PasswordComponent } from '../../reusable/password/password.component';
import { QualificationComponent } from '../../reusable/qualification/qualification.component';
import { BankDetailsComponent } from '../bank-details/bank-details.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { DataTableComponent } from '../data-table/data-table.component';
import { DynamicFilterComponent } from '../dynamic-filter/dynamic-filter.component';
import { ExamDetailsComponent } from '../exam-details/exam-details.component';
import { HeaderComponent } from '../header/header.component';
import { ProfileDetailsComponent } from '../profile-details/profile-details.component';
import { ProfilePhotoComponent } from '../profile-photo/profile-photo.component';
import { ResponsiveDataTableComponent } from '../responsive-data-table/responsive-data-table.component';

// Component
import { SubHeaderComponent } from '../sub-header/sub-header.component';
import { CanAuthActivate, CanLoginActivate, CanPrepAuthActivate, CanProfileActivate, CanStudentParentActivate } from './../../common/auth.gaurd';

@NgModule({
  imports: [
    DataTablesModule,
    TooltipModule.forRoot(),
    NgxFileDropModule,
    MatButtonModule, MatInputModule, MatFormFieldModule, MatSelectModule,
    RouterModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    HttpClientModule,
    FormsModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    NgSelectModule,
    SweetAlert2Module.forRoot(),
    TabsModule.forRoot(),
    MalihuScrollbarModule.forRoot(),
    BnDatatableModule,
    LoadingBarHttpClientModule,
    PaginationModule.forRoot(),
    ImageCropperModule,
    MatTooltipModule,
    TypeaheadModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  declarations: [
    GooglePlacesDirective,
    PasswordComponent,
    BaseComponent,
    SubHeaderComponent,
    DataTableComponent,
    DragDropDirective,
    ResponsiveDataTableComponent,
    GrapesJSComponent,
    ProfileDetailsComponent,
    ChangePasswordComponent,
    BankDetailsComponent,
    QualificationComponent,
    ProfilePhotoComponent,
    HeaderComponent,
    CentresListComponent,
    ExamDetailsComponent,
    DynamicFilterComponent,
    CanAuthActivate, CanLoginActivate, CanPrepAuthActivate, CanStudentParentActivate, CanProfileActivate,

  ],
  providers: [
    CanAuthActivate, CanLoginActivate, CanPrepAuthActivate, CanStudentParentActivate, CanProfileActivate,
  ],
  exports: [
    CalendarModule,
    NgxFileDropModule,
    MatButtonModule, MatInputModule, MatFormFieldModule, MatSelectModule,
    BsDatepickerModule,
    TabsModule,
    HttpClientXsrfModule,
    NgxSpinnerModule,
    ModalModule,
    FormsModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    NgSelectModule,
    SweetAlert2Module,
    MalihuScrollbarModule,
    BnDatatableModule,
    LoadingBarHttpClientModule,
    DragDropDirective,
    PaginationModule,
    ImageCropperModule,
    MatTooltipModule,
    SubHeaderComponent,
    DataTableComponent,
    ResponsiveDataTableComponent,
    GrapesJSComponent,
    PasswordComponent,
    ProfileDetailsComponent,
    ChangePasswordComponent,
    BankDetailsComponent,
    QualificationComponent,
    ProfilePhotoComponent,
    HeaderComponent,
    GooglePlacesDirective,
    CentresListComponent,
    ExamDetailsComponent,
    DynamicFilterComponent,
    TooltipModule,
    CanAuthActivate, CanLoginActivate, CanPrepAuthActivate, CanStudentParentActivate, CanProfileActivate,
  ],
  schemas: [NO_ERRORS_SCHEMA],

})
export class SharedModule { }
