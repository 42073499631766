import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// routing
import { AppRoutingComponents, AppRoutingModule } from './app-routing.module';

// reusable
import { SharedModule } from './reusable/shared/shared.module';

// Common
// tslint:disable-next-line: ordered-imports
// import { CanAuthActivate, CanLoginActivate, CanPrepActivate, CanStudentParentActivate } from './common/auth.gaurd';
import { Broadcaster } from './common/broadCaster';
import { CommonService } from './common/common.service';
import { ErrorMessages } from './common/errorMessages';

// plugins
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { BsDropdownDirective } from 'ngx-bootstrap';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { OwlModule } from 'ngx-owl-carousel';

// component
import { AppComponent } from './app.component';
import { EditStudentDetailsComponent } from './modals/edit-student-details/edit-student-details.component';
import { ViewNotificationComponent } from './modals/view-notification/view-notification.component';

import { AppEntryComponents } from './app-routing.module';
import { StudentCalendarComponent } from './student-calendar/student-calendar.component';
import { StripeCheckoutModule } from 'ng-stripe-checkout';
import { OfflinePaymentComponent } from './modals/offline-payment/offline-payment.component';
import { UploadPermissonComponent } from './modals/upload-permisson/upload-permisson.component';
import { DetailsComponent } from './modals/details/details.component';
import { OfferDetailsComponent } from './modals/offer-details/offer-details.component';
import { EditPaymentComponent } from './modals/edit-payment/edit-payment.component';
import { StudentCalendarPreviousComponent } from './student-calendar-previous/student-calendar-previous.component';
import { SendStudentNotificationsComponent } from './modals/send-student-notifications/send-student-notifications.component';
@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    // CanLoginActivate,
    // CanAuthActivate,
    // CanPrepActivate,
    // CanStudentParentActivate,
    AppRoutingComponents,
    EditStudentDetailsComponent,
    ViewNotificationComponent,
    StudentCalendarComponent,
    OfflinePaymentComponent,
    UploadPermissonComponent,
    DetailsComponent,
    OfferDetailsComponent,
    EditPaymentComponent,
    StudentCalendarPreviousComponent,
    SendStudentNotificationsComponent,

  ],
  imports: [
    SharedModule,
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    StripeCheckoutModule,
    BrowserModule,
    HttpClientModule,
    SweetAlert2Module.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    OwlModule,
  ],
  exports: [
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    // CanLoginActivate,
    // CanAuthActivate,
    // CanStudentParentActivate,
    // CanPrepActivate,
    CommonService,
    BsDropdownDirective,
    ErrorMessages,
    Broadcaster,
    BsModalRef,
  ],
  entryComponents: AppEntryComponents,
})
export class AppModule { }
