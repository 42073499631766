import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '../../common/commonComponent';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: [],
})
export class BankDetailsComponent extends BaseComponent implements OnInit {

  @Input() bank: any = {};
  @Output() bankDetailsChange = new EventEmitter();
  @ViewChild('bankDetailsForm') bankDetailsForm: ElementRef;
  public bankArr: any = ['Andhra bank', 'Axis Bank'];
  subscriber: any;
  submitted: boolean = false;

  constructor(inj: Injector) {
    super(inj);
    //Form submission event
    this.subscriber = this.broadcaster.on<boolean>('submitted').subscribe((success: any) => {
      this.submitted = success;
      this.bankDetailsChange.emit({ data: this.bank, form: this.bankDetailsForm['valid'] });
    });
  }

  ngOnInit() {
    if (this.bank == null) {
      this.bank = {};
    }
  }

  OnDestroy() {
    // destroy instance of subscriber
    this.subscriber.unsubscribe();
  }
}
