import { Component, OnInit, Injector } from '@angular/core';
import { BaseComponent } from '../common/commonComponent';
import { IBreadcrumbs } from '../common/interfaces';
@Component({
  selector: 'app-student-calendar-previous',
  templateUrl: './student-calendar-previous.component.html',
  styles: []
})
export class StudentCalendarPreviousComponent extends BaseComponent implements OnInit {

  constructor(inj: Injector) { super(inj) }

  ngOnInit() {
    this.setBreadcrumbs();
  }
  /*************************************************************/
  // Sub Header
  /*************************************************************/
  public breadcrumbs: IBreadcrumbs[];
  public subHeaderData: any;
  setBreadcrumbs() {
    this.breadcrumbs = [
      { localeKey: 'Home', url: this.URLConstants.COMMMON_DASHBOARD },
      // { localeKey: 'My exam', url: this.URLConstants.MY_EXAMS },
      { localeKey: 'Global Calendar 2020', url: null },
    ];
    this.subHeaderData = {
      title: 'Global Calendar 2020',
      breadcrumbs: this.breadcrumbs,
    };
  }
}
