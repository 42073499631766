import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../common/commonComponent';
import { IBreadcrumbs } from '../common/interfaces';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent extends BaseComponent implements OnInit {

  constructor(public inj: Injector) {
    super(inj);
    this.setBreadcrumbs();
  }

  ngOnInit() {
  }

  /*************************************************************/
  // Sub Header
  /*************************************************************/
  public breadcrumbs: IBreadcrumbs[];
  public subHeaderData: any;
  setBreadcrumbs() {
    this.breadcrumbs = [
      { localeKey: 'Home', url: null },
      { localeKey: 'Dashboard', url: null },
    ];
    this.subHeaderData = {
      title: 'Dashboard',
      breadcrumbs: this.breadcrumbs,
    };
  }
  /*************************************************************/
}
