import { Component, OnInit, Injector } from '@angular/core';
import { BaseComponent } from 'src/app/common/commonComponent';
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-upload-permisson',
  templateUrl: './upload-permisson.component.html',
  styles: []
})
export class UploadPermissonComponent extends BaseComponent implements OnInit {
  public userData: any = {};
  public UpdateCsvUserDeetails: any = {};
  public submitted: any;
  public data: any;

  constructor(inj: Injector,
  ) {
    super(inj);

  }

  ngOnInit() {
  }
  approveTemplate() {
    this.BsModalRef.hide();

    this.broadcaster.broadcast('upload', true);

  }
}
