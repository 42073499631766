import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/common/commonComponent';
import { ITableSetupData } from 'src/app/common/interfaces';

@Component({
  selector: 'app-send-student-notifications',
  templateUrl: './send-student-notifications.component.html',
  styles: []
})
export class SendStudentNotificationsComponent extends BaseComponent implements OnInit {
  user: any = {};
  userData: any = {};
  submitted: boolean = false;
  public data;
  public qualificationDatesArr: any =[];
  oldScheduleExamId: any;
  public recipientTypeArr: any[] = [{ name: 'Leads' }, { name: 'Registered Candidates' }, { name: 'Candidates To Be Registered' }];
  listing: any;
  public isLeadList: boolean = false;
  public CSVLeadData: any ={};
  public tableSetupData: ITableSetupData = { cols: [], actions: null, type: null, params: {}, conditions: {}, noText: null };

  constructor(inj: Injector) {
    super(inj);
  }

  ngOnInit() {
    this.user = Object.assign({}, this.data);
this.getQualificationDate();
this.oldScheduleExamId=this.data.bookedForQualification;
this.user.scheduleExamId=this.data.bookedForQualification;
if(this.user.recipientType ==='Leads'){
  this.CSVLeadData.qualification=this.user.qualificationId;
  this.setDTableInitialData();
  this.isLeadList=true;
  // this.commonService.callApi(this.callAPIConstants.CSVLeadUsers,{page:1,pagesize:100,qualification:this.user.qualificationId},'post').then(res=>{
  //   if(res.status === 1){
  //     this.listing=res.data.listing;
      
  //   }
  // })
}
  }
  getQualificationDate() {
    let apiData: any = {};
    apiData.qualification = this.user.qualificationId;
    // apiData.authcentreId = this.user.authCentreId;
    // apiData.city = this.user.city;
    this.commonService.callApi(this.callAPIConstants.getExamDatesBasedOnQualification, apiData, 'post', false).then(res => {
      if (res.status === 1) {
        this.qualificationDatesArr = res.data;
      }
    })
  }
  /**********************************************************************
       @PURPOSE      :  For calling UpdateCsvUserDetails API
       @PARAMETERS   : {
           form: Form, It is to know Form valid or not
           user: Object, User data
       }
   /**********************************************************************/
  sendNotification(form, user) {
    this.submitted = true;
    if (form.valid) {
      let data = {
        templateId: this.user._id,
        qualificationId:this.user.qualificationId,
        qualificationDateId:this.user.qualificationDateId,
       };
      this.commonService.callApi(this.callAPIConstants.massEmailSMSNotificationSend, data, 'post', false, false).then((success) => {
        if (success.status === 1) {
          this.popToast('success', success.message);
          this.BsModalRef.hide();
          this.broadcaster.broadcast('detailsUpdated', 'CsvUserlist');
        } else {
          this.popToast('error', success.message);
        }
      });
    }
  }
   // sort:Boolean ==> to enable sorting
  // isVisible:Boolean ==>  show static column
  // columnVisibility:Boolean ==> dynamic changing visibility of columns
  // filter ==> filter for that colName

  /*************************************************************/

  setDTableInitialData() {
    let tempData = [
      { type: 'multipleSelection', colName: '', colFieldname: '', isVisible: true },
      // { type: 'code', colName: 'CODE', colFieldname: 'code', sort: true, isVisible: true, columnVisibility: true, filter: false },
      { type: 'text', colName: 'File Name', colFieldname: 'csvFileName', sort: true, isVisible: true, columnVisibility: true, filter: true },
      // { type: 'text', colName: 'Uploaded By', colFieldname: 'csvCentreRole', sort: true, isVisible: true, columnVisibility: true, filter: true },
      // { type: 'text', colName: 'REGION', colFieldname: 'region', sort: true, isVisible: true, columnVisibility: true, filter: true },
      { type: 'text', colName: 'Centre  Name', colFieldname: 'csvCentreName', sort: true, isVisible: true, columnVisibility: true, filter: true },
      // { type: 'text', colName: 'Entity Type', colFieldname: 'categoryName', sort: true, isVisible: true, columnVisibility: true, filter: true },
      { type: 'text', colName: 'Exam', colFieldname: 'csvQualificationName', sort: true, isVisible: true, columnVisibility: true, filter: true },
      // { type: 'text', colName: 'Uploaded Date & Time', colFieldname: 'updatedAt', sort: true, isVisible: true, fullDate: true },
      // { type: 'action', colName: 'Action', colFieldname: '', isVisible: true },
      // { type: 'text', colName: 'Authorised Centre', colFieldname: 'AC', sort: false, isVisible: false, filter: true },
      // { type: 'status', colName: 'Status', colFieldname: 'status', isVisible: true, columnVisibility: true, filter: true},
    ]

    this.tableSetupData.cols = tempData;
    this.tableSetupData.type = "csvLeadsList1";
    this.tableSetupData.noText = 'CSV';
    this.tableSetupData.actions = [
      { id: 2, buttonTitle: 'Edit', class: 'fa fa-pencil', type: 'send-btn' },
      // { id: 2, buttonTitle: 'Edit', class: 'fa fa-pencil', type: 'icon',},
      // { id: 3, buttonTitle: 'Delete', class: 'fa fa-trash text-danger', type: 'icon', }
    ];
    this.tableSetupData.params = { deleteParams: 'userIds', statusParams: this.user._id };
    this.tableSetupData.conditions = {
      showTableHeader: false, showTableFooter: true, showApplyStatus: false, showExport: false,
      showColumnHide: false, showFilter: false, showFilterExport: false, showSaveFilter: false, search: false, showButton: {}
    };
  }
}

