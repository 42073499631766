import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { APP_ID, Component, Injector, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { makeStateKey, Meta, Title, TransferState } from '@angular/platform-browser';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as jQuery from 'jquery';

declare var $: any;

// plugin
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';
import { Broadcaster } from './broadCaster';

// common
import { CommonService } from './common.service';
import { ErrorMessages } from './errorMessages';

// constants
import { callAPIConstants } from './constants/callAPI-constants';
import { URLConstants } from './constants/routerLink-constants';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'parent-comp',
  template: ``,
  providers: [],
})

export class BaseComponent {

  constructor(injector: Injector) {
    this.router = injector.get(Router);
    this.platformId = injector.get(PLATFORM_ID);
    this.appId = injector.get(APP_ID);
    this.commonService = injector.get(CommonService);
    this.errorMessage = injector.get(ErrorMessages);
    this.http = injector.get(HttpClient);
    this.titleService = injector.get(Title);
    this.metaService = injector.get(Meta);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.modalService = injector.get(BsModalService);
    this.BsModalRef = injector.get(BsModalRef);
    this.baseUrl = this.commonService._apiUrl;
    this.spinner = injector.get(NgxSpinnerService);
    this.broadcaster = injector.get(Broadcaster);
    // tslint:disable-next-line: deprecation
    this.sanitize = injector.get(DomSanitizer);
    this.loginData = JSON.parse(this.getToken('data'));
    this.location = injector.get(Location);
  }
  public loginData: any;
  public sanitize: DomSanitizer;
  public BsModalRef: BsModalRef;
  public activatedRoute: ActivatedRoute;
  public errorMessage: ErrorMessages;
  public modalService: BsModalService;
  // tslint:disable-next-line: deprecation
  public swal = swal;
  public titleService: Title;
  public metaService: Meta;
  public platformId: any;
  public appId: any;
  public http: any;
  public router: Router;
  public commonService: CommonService;
  public baseUrl;
  public $ = jQuery;
  public spinner: NgxSpinnerService;
  public broadcaster: Broadcaster;
  URLConstants = URLConstants;
  public callAPIConstants = callAPIConstants;
  public location: any;

  // *************************************************************//
  //@Purpose : To check server or browser
  //*************************************************************//
  isBrowser() {
    if (isPlatformBrowser(this.platformId)) {
      return true;
    } else {
      return false;
    }
  }
  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit() {
    this.animateInput();
  }
  animateInput() {
    // tslint:disable-next-line: no-dead-store
    let that = this;
  }

  // *************************************************************//
  //@Purpose : We can use following function to use SessionStoarge
  //*************************************************************//
  setToken(key, value) {
    if (isPlatformBrowser(this.platformId)) {
      window.sessionStorage.setItem(key, value);
    }
  }
  getToken(key) {
    if (isPlatformBrowser(this.platformId)) {
      return window.sessionStorage.getItem(key);
    }
  }
  removeToken(key) {
    if (isPlatformBrowser(this.platformId)) {
      window.sessionStorage.removeItem(key);
    }
  }
  clearToken() {
    if (isPlatformBrowser(this.platformId)) {
      window.sessionStorage.clear();
    }
  }
  //*************************************************************//

  //*************************************************************//
  //@Purpose : We can use following function to use Toaster Service.
  //*************************************************************//
  popToast(type, title) {
    swal({
      position: 'center',
      type,
      text: title,
      showConfirmButton: false,
      timer: 3000,
      customClass: 'custom-toaster',

    });
  }
  confirmpopToast(type, title) {
    return swal({

      // title: 'Are you sure?',
      text: title,
      type,
      showCancelButton: true,
      confirmButtonColor: '#00619e',
      cancelButtonColor: 'darkgray',
      confirmButtonText: 'DELETE',
      confirmButtonClass: 'btn btn-primary',
      cancelButtonClass: 'btn btn--outline-primary',

    });
  }

  /****************************************************************************
  @PURPOSE      : To restrict or allow some values in input.
  @PARAMETERS   : $event
  @RETURN       : Boolen
  ****************************************************************************/
  RestrictSpace(e) {
    if (e.keyCode === 32) {
      return false;
    } else {
      return true;
    }
  }

  AllowNumbers(e) {
    if (!e.target.value.length && e.key <= 0) {
      return false;
    }
    let input;
    if (e.metaKey ||
      e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which < 33) {
      return true;
    }
    if (e.which === 43 ||
      e.which === 45) {
      return true;
    }
    if (e.which === 36 ||
      e.which === 35) {
      return true;
    }
    if (e.which === 37 ||
      e.which === 39) {
      return true;
    }
    input = e.key;
    return !!/[\d\s]/.test(input);
  }

  AllowChar(e) {
    // tslint:disable-next-line: max-line-length
    if ((e.keyCode > 64 && e.keyCode < 91) || (e.keyCode > 96 && e.keyCode < 123) || e.keyCode === 8 || e.keyCode === 32 || e.keyCode === 9) {
      return true;
    } else {
      return false;
    }
  }
  /****************************************************************************/

  /****************************************************************************/
  //@Logout
  /****************************************************************************/
  logout() {
    // tslint:disable-next-line: one-variable-per-declaration
    let inic_id, inic_pass, remember;
    if (this.getToken('inic_id') && this.getToken('inic_pass')) {
      inic_id = this.getToken('inic_id');
      inic_pass = this.getToken('inic_pass');
      remember = true;
    }
    this.clearToken();
    if (remember) {
      this.setToken('inic_id', inic_id);
      this.setToken('inic_pass', inic_pass);
    }
    this.router.navigate([URLConstants.LOGIN]);
  }
  /****************************************************************************/

  /****************************************************************************
  @PURPOSE      : To show validation message
  @PARAMETERS   : <field_name, errorObj?>
  @RETURN       : error message.
  ****************************************************************************/
  showError(field, errorObj?) {
    return this.errorMessage.getError(field, errorObj);
  }
  /****************************************************************************/

  /****************************************************************************/
  // Get Profile Image
  /****************************************************************************/
  getProfile() {
    const url = this.getToken('ss_pic');
    if (url == null || url === ' ') {
      return 'assets/images/NoProfile.png';
    } else {
      return url;
    }
  }
  /****************************************************************************/

  /****************************************************************************/
  // Get Pemission according to role
  /****************************************************************************/
  getRolePermissions(key) {
    let role;
    if (this.getToken('role_permission')) {
      role = this.getToken('role_permission');
      return role;
    }
  }

  hideActionInTable(tempData) {
    let index = tempData.findIndex((o) => o.type === 'action');
    tempData[index].isVisible = false;
  }
  /****************************************************************************/

  /****************************************************************************/
  // common function for AUTH GUARD (if returns false then routes to Dashboard)
  /****************************************************************************/

  CanActivateFalseRouteDashboard(condition) {
    if (condition) {
      return true;
    }
    this.router.navigate([this.URLConstants.DASHBOARD]);
    return false;
  }

  noAccessToAuthorizedPages() {
    if (this.getToken('accessToken')) {
      return true;
    }
    this.router.navigate([this.URLConstants.LOGIN]);
    return false;
  }

  /****************************************************************************
  //For Side menu toggle
  /****************************************************************************/
  slideLeft() {
    // tslint:disable-next-line: no-duplicate-string
    $('body').addClass('slide-open');
  }
  removeSlide() {
    $('body').removeClass('slide-open');
  }
  slideClose() {
    $('body').removeClass('slide-open');
  }

  /****************************************************************************/
}
