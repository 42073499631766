import { Component, OnInit, Injector } from '@angular/core';
import { BaseComponent } from 'src/app/common/commonComponent';
import { BsModalRef } from 'ngx-bootstrap';
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-offline-payment',
  templateUrl: './offline-payment.component.html',
  styles: []
})
export class OfflinePaymentComponent extends BaseComponent implements OnInit {
  public payment: any = {};
  public approved: boolean;
  public payementdata: any;
  constructor(inj: Injector, public modalRef: BsModalRef) {
    super(inj);

  }

  ngOnInit() {

  }
  //approve the payment status
  public toggle: boolean = false;
  approvedPaymentStatus(payment) {
    this.approved = true;
    if (payment.status) {
      let data = {
        bookedPaymentType: payment.status,
        _id: this.payementdata.bookExamdataId
      }
      this.commonService.callApi(this.callAPIConstants.ApprovePayementStatus, data, 'post', false, false).then((success: any) => {
        if (success.status === 1) {
          this.popToast("success", success.message);
          this.broadcaster.broadcast('offlinePayment', true);
          this.modalRef.hide();
          this.payment = {};
          this.approved = false;
          this.toggle = true;
        }
        else {
          this.popToast('error', success.message)
        }
      })
    }
  }
}
