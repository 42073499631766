export const messages = {
    ERROR: {
        REQUIRED: {
            region: 'Select Region',
            qualification: 'Select Exam',
            center: 'Select Authorisation Centre',
            categoryId: 'Select Entity Type',
            terms: 'Please select our Terms of Use',
            userType: 'select role',
            otp: 'Enter OTP',
            Username: 'Enter userName',
            userName: 'Enter User Name',
            emailId: 'Enter Email',
            address: 'Enter Address',
            City: 'Select City',
            State: 'Select State',
            Country: 'Select Country',
            country: 'Select Country',
            Entity: 'Enter Entity Type',
            phoneNo: ' Enter Phone Number',
            mobile: ' Enter Contact Number',
            Password: 'Enter Password',
            confirmPassword: 'Enter Confirm Password',
            newPassword: 'Enter New Password',
            oldPassword: 'Enter Current Password',
            bank: 'Select Bank',
            accountNumber: 'Enter Account Number',
            holderName: 'Enter Account Holder Name',
            code: 'Enter Bank Code',
            year: 'Select Year',
            grade: 'Select Grade',
            stream: 'Select Stream',
            contactPersonName: 'Enter Contact Person Name',
            centreName: 'Enter Preparation Centre Name',
            faxNumber: 'Enter Fax Number',
            zipcode: 'Enter zipcode',
            centreNumber: 'Enter Centre Number',
            candidateName: 'Enter candidate Name',
            templateType: 'Enter Template Type',
            templateName: 'Enter Template Name',
            qualificationName: 'Select Exam',
            templateData: 'Enter Template Content',
            message: 'Enter Message',
            emailType: 'Select Type',
            type: 'Select Type',
            exam: 'Select Exam',
            description: 'Enter Description',
            Date: 'Select Date',
            amount: 'Enter Amount',
            Title: 'Enter Title',
            firstname: 'Enter First Name',
            lastname: 'Enter Last Name',
            roleName: 'Enter Role',
            technology: 'Select Technology',
            Amount: ' Enter Amount',
            filterName: 'Enter name of Filter',
            emailKey: 'Enter Title',
            subject: 'Enter Subject',
            fromEmail: 'Enter From E-mail',
            emailTemplateId: 'Select Event',
            host: 'Enter Host',
            port: 'Enter Port',
            defaultFromEmail: 'Enter default From e-mail',
            defaultAdminEmail: 'Enter default Admin e-mail',
            metaKeyword: 'Enter Meta Keyword',
            pageTitle: 'Enter Page Title',
            currency: 'Select Currency',
            publishableKey: 'Enter Publishable Key',
            secretKey: 'Enter Secret Key',
            preparation: 'Select Preparation Centre',
            categoryType: 'Select Entity Type',
            recipientType: 'Select Recipient Type',
            pcId: 'Enter Preparation Centre Id',
            candidateType: 'Select Candidate Type',
            price: 'Select Exam',
            qualificationDate: "Choose Exam Date",
            emails: "Please Select Recieve Emails",
            studentType:"Please Select Student Type"

        },
        PATTERN: {
            Age: 'Please enter proper Age',
            mobile: 'Phone number can not be less than 4 characters',
            Email: 'Please enter a valid email address',
            // tslint:disable-next-line: max-line-length
            Password: 'Password must contain atleast six characters long, one lowercase letter, one uppercase letter, one special character and a number.',
            // tslint:disable-next-line: max-line-length
            newPassword: 'Password must contain atleast six characters long, one lowercase letter, one uppercase letter, one special character and a number.',
            Username: 'Invalid Username',
            Contactno: 'Invalid Contact Number',
            emailId: 'Enter valid E-mail',
        },
        MINLENGTH: {
            phoneNo: 'Phone number should be 10 digits',
            mobile: 'contact number should be 10 digits',
            faxNumber: 'FaxNumber shouls be 10 digits',
            zipcode: 'zipcode should be 6 digits',
            pcId: 'Preparation Centre Id should be 6 digits',
            Password: 'Password must be atleast 6 characters',
            bankCode: 'Bank Code should be 10 digits',
            Distance: 'Distance should be minimum 1 characters',
            PinNo: 'Please enter minimum 6 pin number',
            LastName: 'Distance should not be more than 5 chartacters',
            Username: 'Username must be atleast 5 characters',
            port: 'Minimum 3 didgit required',
        },
        CUSTOM: {
            Location: 'Please enter valid location',
            Subject: 'Please select subject from available list',
        },
    },
};
