import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
// plugins
import swal from 'sweetalert2';
// configs
import { config } from '../../assets/config/configs';
// constants
import { URLConstants } from './constants/routerLink-constants';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  authorised: any = false;
  constructor(
    public router: Router,
    public _http: HttpClient,
    @Inject(PLATFORM_ID) platformId: object,
  ) {
    this.platformId = platformId;
    this._apiUrl = this.config.apiUrl;

  }

  // tslint:disable-next-line: deprecation
  public swal = swal;
  public config = config as any;
  public _apiUrl = '';
  public platformId;

  public getToken(key) {
    if (isPlatformBrowser(this.platformId)) {
      return window.sessionStorage.getItem(key);
    }
  }
  public setToken(key, value) {
    if (isPlatformBrowser(this.platformId)) {
      window.sessionStorage.setItem(key, value);
    }
  }

  /*******************************************************************************************
      @PURPOSE      	: 	Call api.
      @Parameters 	: 	{
            url : <url of api>
            data : <data object (JSON)>
            method : String (get, post)
            isForm (Optional) : Boolean - to call api with form data
            isPublic (Optional) : Boolean - to call api without auth header
          }
  /*****************************************************************************************/
  callApi(url, data, method, isPublic?, isForm?, html?): Promise<any> {
    let headers;
    if (isPublic) {
      // tslint:disable-next-line: no-duplicate-string
      headers = new HttpHeaders({ 'content-Type': 'application/json' });
    } else if (html) {
      headers = new HttpHeaders({ 'content-Type': 'text/html', 'Authorization': this.getToken('accessToken') });
    } else {
      headers = new HttpHeaders({ 'content-Type': 'application/json', 'Authorization': this.getToken('accessToken') });
    }
    if (isForm) {
      headers = new HttpHeaders({ Authorization: this.getToken('accessToken') });
    }
    return new Promise((resolve, reject) => {
      if (method === 'post') {
        this._http.post(this._apiUrl + url, data, { headers }).subscribe((data) => {
          resolve(data);
        }, (error) => {
          this.error(error);
        });
      } else if (method === 'get') {
        this._http.get(this._apiUrl + url, { headers, params: data }).subscribe((data) => {
          resolve(data);
        }, (error) => {
          this.error(error);
        });
      } else if (method === 'put') {
        this._http.put(this._apiUrl + url, data, { headers }).subscribe((data) => {
          resolve(data);
        }, (error) => {

        });
      } else if (method === 'delete') {
        this._http.delete(this._apiUrl + url, { headers }).subscribe((data) => {
          resolve(data);
        }, (error) => {
          this.error(error);
        });
      }
    });

  }

  callApiObservable(url, data) {
    let headers = new HttpHeaders({
      'content-Type': 'application/json',
      'Authorization': this.getToken('accessToken'),
    });
    return this._http.get(this._apiUrl + url, { headers, params: data }).pipe(map((rsp) => {
      return rsp;
    }),
    );
  }

  /*****************************************************************************************/
  // @PURPOSE      	: 	To show server error
  /*****************************************************************************************/
  showServerError(e) {

    this.swal({
      position: 'center',
      type: 'error',
      text: 'Internal Server Error',
      showConfirmButton: false,
      timer: 1800,
      // tslint:disable-next-line: no-duplicate-string
      customClass: 'custom-toaster',
    });
  }
  /****************************************************************************/

  /*****************************************************************************************/
  // @PURPOSE      	: 	To show session LogOut popup
  /*****************************************************************************************/
  sessionLogOut() {
    if (isPlatformBrowser(this.platformId)) {
      // window.sessionStoarge.clear();
      window.sessionStorage.removeItem('accessToken');
      window.sessionStorage.removeItem('data');
      window.sessionStorage.removeItem('photo');
      window.sessionStorage.removeItem('acId');
    }
    this.swal({
      position: 'center',
      type: 'error',
      text: 'Session Timeout',
      showConfirmButton: false,
      timer: 1800,
      customClass: 'custom-toaster',
    });
    this.router.navigate([URLConstants.LOGIN]);
  }
  /****************************************************************************/

  /*****************************************************************************************/
  // @PURPOSE      	: 	To error on status 401(on unautorized login)
  /*****************************************************************************************/
  error(error) {
    if (error.status === 401) {
      this.sessionLogOut();
    }
    if (error.status === 422) {
      this.swal({
        position: 'center',
        type: 'error',
        text: 'Something went wrong!',
        showConfirmButton: false,
        timer: 1800,
        customClass: 'custom-toaster',
      });
    }
  }
  /****************************************************************************/

  /*****************************************************************************************/
  // @PURPOSE      	: 	The set method is use for encrypt the value.
  /*****************************************************************************************/
  // encrypt(data) {
  //   let encrypt = CryptoJS.AES.encrypt(JSON.stringify(data), 'secret key 123');
  //   return encrypt;
  // }
  /****************************************************************************/

  /*****************************************************************************************/
  // @PURPOSE      	: 	The get method is use for decrypt the value.
  /*****************************************************************************************/
  // decrypt(data) {
  //   let value = CryptoJS.AES.decrypt(data.toString(), 'secret key 123');
  //   let decrypt = JSON.parse(value.toString(CryptoJS.enc.Utf8));
  //   return decrypt;
  // }
  /****************************************************************************/
}
