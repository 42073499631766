import { Component, Injector, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../common/commonComponent';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
})

export class SubHeaderComponent extends BaseComponent implements OnInit {
  // tslint:disable-next-line: no-input-rename
  @Input('subHeaderData') subHeaderData: any;

  constructor(inj: Injector) {
    super(inj);
  }

  ngOnInit() { }
  if(){}

}
