import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../../common/commonComponent';
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-view-notification',
  templateUrl: './view-notification.component.html',
})
export class ViewNotificationComponent extends BaseComponent implements OnInit {
  public data;
  userData: any;

  constructor(inj: Injector) {
    super(inj);

  }

  ngOnInit() {
    this.userData = Object.assign({}, this.data);
  }
}
