import { Component, Injector, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../common/commonComponent';
@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styles: [],
})
export class PasswordComponent extends BaseComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('passwordData') passwordData;
  public getMethodName: any;
  public token: any;
  public password: any = {};
  public submitted: boolean = false;
  constructor(public inj: Injector) {
    super(inj);
    this.token = this.router.parseUrl(this.router.url).queryParams['token'];
  }

  ngOnInit() {
    if (this.passwordData.type === 'setPassword') {
      this.getMethodName = this.callAPIConstants.ACPCSetPassword;
    } else if (this.passwordData.type === 'resetPassword') {
      this.getMethodName = this.callAPIConstants.ResetPassword;
    }
  }
  /*************************************************************/
  // set or reset password
  /*************************************************************/

  onSubmitPassword(passwordForm, password) {
    this.submitted = true;
    if (passwordForm.valid && (password.confirmPassword === password.newPassword)) {
      let formObject = {
        token: this.token,
        password: password.newPassword,
      };
      this.commonService.callApi(this.getMethodName, formObject, 'post', true, false).then((success: any) => {
        if (success.status === 1) {
          this.popToast('success', success.message);
          this.router.navigate([this.URLConstants.LOGIN]);
        } else {
          this.popToast('error', success.message);
        }
      });
    }
  }
  /*************************************************************/
}
