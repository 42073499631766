import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { messages } from './errorMessagesData';

@Injectable()
export class ErrorMessages {
  public MSG = (messages as any);

  constructor() { }
  // tslint:disable-next-line: no-big-function
  getError(field, error) {
    let message = '';
    if (error) {
      if (error.required) {
        const required = this.MSG.ERROR.REQUIRED;
        // tslint:disable-next-line: max-switch-cases
        switch (field) {
          case 'firstname': { message = required.firstname; } break;
          case 'lastname': { message = required.lastname; } break;
          case 'technology': { message = required.technology; } break;
          case 'emailId': { message = required.emailId; } break;
          case 'roleName': { message = required.roleName; } break;
          case 'filterName': { message = required.filterName; } break;
          case 'emailKey': { message = required.emailKey; } break
          case 'subject': { message = required.subject; } break;
          case 'fromEmail': { message = required.fromEmail; } break;
          case 'emailTemplateId': { message = required.emailTemplateId; } break;
          case 'host': { message = required.host; } break;
          case 'port': { message = required.port; } break;
          case 'defaultFromEmail': { message = required.defaultFromEmail; } break
          case 'metaKeyword': { message = required.metaKeyword; } break;
          case 'pageTitle': { message = required.pageTitle; } break;
          case 'currency': { message = required.currency; } break;
          case 'region': { message = required.region; } break;
          case 'center': { message = required.center; } break;
          case 'categoryId': { message = required.categoryId; } break;
          case 'qualification': { message = required.qualification; } break;
          case 'qualificationName': { message = required.qualificationName; } break;
          case 'terms': { message = required.terms; } break;
          case 'templateData': { message = required.templateData; } break;
          case 'userType': { message = required.userType; } break;
          case 'confirmPassword': { message = required.confirmPassword; } break;
          case 'otp': { message = required.otp; } break;
          case 'username': { message = required.Username; } break;
          case 'address': { message = required.address; } break;
          case 'city': { message = required.City; } break;
          case 'state': { message = required.State; } break;
          case 'country': { message = required.Country; } break;
          case 'location': { message = required.country; } break;
          case 'entityType': { message = required.Entity; } break;
          case 'phoneNo': { message = required.phoneNo; } break;
          case 'password': { message = required.Password; } break;
          case 'newPassword': { message = required.newPassword; } break;
          case 'oldPassword': { message = required.oldPassword; } break;
          case 'bankName': { message = required.bank; } break;
          case 'accountNo': { message = required.accountNumber; } break;
          case 'accountHolderName': { message = required.holderName; } break;
          case 'bankCode': { message = required.code; } break;
          case 'year': { message = required.year; } break;
          case 'grade': { message = required.grade; } break;
          case 'stream': { message = required.stream; } break;
          case 'centreContactPersonName': { message = required.contactPersonName; } break;
          case 'centreName': { message = required.centreName; } break;
          case 'faxNumber': { message = required.faxNumber; } break;
          case 'zipcode': { message = required.zipcode; } break;
          case 'centreNumber': { message = required.centreNumber; } break;
          case 'candidateName': { message = required.candidateName; } break;
          case 'mobile': { message = required.mobile; } break;
          case 'templateType': { message = required.templateType; } break;
          case 'templateName': { message = required.templateName; } break;
          case 'exam': { message = required.exam; } break;
          // case 'type': {   // tslint:disable-next-line: no-dead-store   message = required.type; }
          case 'emailType': { message = required.emailType; } break;
          case 'preparation': { message = required.preparation; } break;
          case 'date': { message = required.Date; } break;
          case 'amount': { message = required.amount; } break;
          case 'description': { message = required.description; } break;
          case 'title': { message = required.Title; } break;
          case 'defaultAdminEmail': { message = required.defaultAdminEmail; } break;
          case 'publishableKey': { message = required.publishableKey; } break;
          case 'secretKey': { message = required.secretKey; } break;
          case 'message': { message = required.message; } break;
          case 'userName': { message = required.userName; } break;
          case 'categoryType': { message = required.categoryType; } break;
          case 'recipientType': { message = required.recipientType; } break;
          case 'pcId': { message = required.pcId; } break;
          case 'qualificationDate': { message = required.qualificationDate; } break;
          case 'candidateType': { message = required.candidateType; } break;
          case 'price': { message = required.price; } break;
          case 'emails': { message = required.emails; } break;
          case 'studentType': { message = required.studentType; } break;

        }
      } else if (error.pattern) {
        const pattern = this.MSG.ERROR.PATTERN;
        switch (field) {
          case 'emailId': { message = pattern.Email; } break;
          case 'password': { message = pattern.Password; } break;
          case 'newPassword': { message = pattern.newPassword; } break;
          case 'adminEmail': { message = pattern.adminEmail; } break;
          case 'fromEmail': { message = pattern.fromEmail; } break;
          case 'defaultFromEmail': { message = pattern.Email; } break;
          case 'defaultAdminEmail': { message = pattern.Email; } break;
        }
      } else if (error.minlength) {
        const minlength = this.MSG.ERROR.MINLENGTH;
        switch (field) {
          case 'zipcode': { message = minlength.zipcode; } break;
          case 'phoneNo': { message = minlength.phoneNo; } break;
          case 'bankCode': { message = minlength.bankCode; } break;
          case 'mobile': { message = minlength.mobile; } break;
          case 'faxNumber': { message = minlength.faxNumber; } break;
          case 'otp': { message = minlength.otp; } break;
          case 'confirmPassword': { message = minlength.confirmPassword; } break;
          case 'port': { message = minlength.port; } break;
          case 'pcId': { message = minlength.pcId; }
        }
      }
      return message;
    }
  }
}
