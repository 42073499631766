import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '../../common/commonComponent';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: [],
})
export class ChangePasswordComponent extends BaseComponent implements OnInit {

  @Input() user: any = {};
  @Output() passwordChange = new EventEmitter();
  @ViewChild('changePasswordForm') changePasswordForm: ElementRef;
  subscriber: any;
  submittedPassword: boolean = false;

  constructor(inj: Injector) {
    super(inj);
    //Form submission event
    // if (!this.loginData.isProfileUpdated) {
    this.subscriber = this.broadcaster.on<boolean>('submitted').subscribe((success: any) => {
      this.submittedPassword = success;
      this.passwordChange.emit({ data: this.user, form: this.changePasswordForm['valid'] });
    });
    // }
  }

  ngOnInit() { }
  // tslint:disable-next-line: use-life-cycle-interface
  ngOnDestroy() {
    // destroy instance of subscriber
    // if (this.loginData.role !== 'Student/Parents') {
    this.subscriber.unsubscribe();
    // }
  }

}
