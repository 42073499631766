import { Component, OnInit } from '@angular/core';
import { BaseComponent } from './common/commonComponent';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent extends BaseComponent implements OnInit {
  title = 'cambridge';
  isLogin: boolean;

  ngOnInit() {

  }
  ngDoCheck() {
    if (this.platformId) {
      if (this.getToken("accessToken")) {
        this.isLogin = true;
      } else {
        this.isLogin = false;
      }
    }
  }
}
